function SendLexTokenAndroid(token)
{
  try {
    window.appRef.FetchToken(token);
  } catch (error) {
    
  }
  
}
function SendLexTokenSwift(token)
{
  try {
    webkit.messageHandlers.Token.postMessage(
        {
            "Token": token
        }
    );
  } catch (error) {
    
  }
  
}

function WebViewLogout()
{
  WebViewLogoutAndroid();
  WebViewLogoutSwift();
  
}

function WebViewLogoutAndroid()
{
  try {
    window.appRef.LogOut();
  } catch (error) {
    
  }
  
}

function WebViewLogoutSwift()
{
  try {
    webkit.messageHandlers.LogOut.postMessage(
        {
            "LogOut":"Y"
        }
    );
  } catch (error) {
    
  }
  
}

function OpenMailer(mailId)
{
    OpenMailerAndroid(mailId);
    OpenMailerSwift(mailId);
}

function OpenMailerAndroid(mailId)
{
    try {
        window.appRef.OpenMailer(mailId);
    } catch (error) {
        
    }
}
function OpenMailerSwift(mailId)
{
    try {
        webkit.messageHandlers.MailId.postMessage(
            {
                "MailId":mailId
            }
        );
    } catch (error) {
        
    }
}

function OpenDialler(mobile)
{
    OpenDiallerAndroid(mobile);
    OpenDiallerSwift(mobile);
}

function OpenDiallerAndroid(mobile)
{
    try {
        window.appRef.OpenDialler(mobile);
    } catch (error) {
        
    }
}
function OpenDiallerSwift(mobile)
{
    try {
        webkit.messageHandlers.Mobile.postMessage(
            {
                "Mobile":mobile
            }
        );
    } catch (error) {
        
    }
}

function OpenUrl(url)
{
    OpenUrlAndroid(url);
    OpenUrlSwift(url);
}

function OpenUrlAndroid(url)
{
    try {
        window.appRef.OpenUrl(url);
    } catch (error) {
        
    }
}
function OpenUrlSwift(url)
{
    try {
        webkit.messageHandlers.URL.postMessage(
            {
                "URL":url
            }
        );
    } catch (error) {
        
    }
}
function ShowBottomBar()
{
    // alert('Inside bottom bar');
    ShowBottomBarAndroid();
    ShowBottomBarSwift();
}

function ShowBottomBarAndroid()
{
    try {
        window.appRef.ShowBottomBar();
    } catch (error) {
        
    }
}
function ShowBottomBarSwift()
{
    try {
        webkit.messageHandlers.Show.postMessage(
            {
                "Show":"Y"
            }
        );
    } catch (error) {
        
    }
}

function HideBottomBar()
{
    HideBottomBarAndroid();
    HideBottomBarSwift();
}

function HideBottomBarAndroid()
{
    try {
        window.appRef.HideBottomBar();
    } catch (error) {
        
    }
}
function HideBottomBarSwift()
{
    try {
        webkit.messageHandlers.Hide.postMessage(
            {
                "Hide":"Y"
            }
        );
    } catch (error) {
        
    }
}

function GetBase64Data(stream,name)
{
    GetBase64DataAndroid(stream,name);
    GetBase64DataSwift(stream,name);
}

function GetBase64DataAndroid(stream,name)
{
    try {
        window.appRef.GetBase64Data(stream,name);        
    } catch (error) {
        
    }
}

function GetBase64DataSwift(stream,name)
{
    try {
        webkit.messageHandlers.stream.postMessage(
            {
                "stream":stream,
                "name":name
            }
        );       
    } catch (error) {
        
    }
}

function SetReminder(doj,text)
{
    SetReminderAndroid(doj,text);
    SetReminderSwift(doj,text);
}

function SetReminderAndroid(doj,text)
{
    try {
        window.appRef.SetReminder(doj,text);        
    } catch (error) {
        
    }
}

function SetReminderSwift(doj,text)
{
    try {
        webkit.messageHandlers.Reminder.postMessage(
            {
                "DOJ":doj,
                "Message":text
            }
        );       
    } catch (error) {
        
    }
}

function Exit()
{
    ExitAndroid();
    ExitSwift();
}

function ExitAndroid()
{
    try {
        window.appRef.Exit();        
    } catch (error) {
        
    }
}

function ExitSwift()
{
    try {
        webkit.messageHandlers.Exit.postMessage(
            {
                "Exit":"Y"
            }
        );       
    } catch (error) {
        
    }
}

function Redirect(url)
{
    RedirectAndroid(url);
    RedirectSwift(url);
}

function RedirectAndroid(url)
{
    try {
        window.appRef.Redirect(url);        
    } catch (error) {
        
    }
}

function RedirectSwift(url)
{
    try {
        webkit.messageHandlers.Redirect.postMessage(
            {
                "Redirect":url
            }
        );       
    } catch (error) {
        
    }
}

function OpenPDF(url)
{
    OpenPDFAndroid(url);
    OpenPDFSwift(url);
}

function OpenPDFAndroid(url)
{
    try {
        window.appRef.OpenPDF(url);
    } catch (error) {
        
    }
}
function OpenPDFSwift(url)
{
    try {
        webkit.messageHandlers.PDF.postMessage(
            {
                "PDF":url
            }
        );
    } catch (error) {
        
    }
}

function StopSpinner()
{
    StopSpinnerAndroid();
    StopSpinnerSwift();
}

function StopSpinnerAndroid()
{
    try {
        window.appRef.StopSpinner();
    } catch (error) {
        
    }
}
function StopSpinnerSwift()
{
    try {
        webkit.messageHandlers.StopSpinner.postMessage(
            {
                "StopSpinner":"Y"
            }
        );
    } catch (error) {
        
    }
}

function OpenInfyMeUrl()
{
    OpenUrlAndroid("https://play.google.com/store/apps/details?id=com.infosys.it.infosysitapp");
    OpenUrlSwift("https://itunes.apple.com/in/app/intune-company-portal/id719171358?mt=8");
}

function InsertLoginDetails(candid,Token){
    InsertLoginDetailsAndroid(candid,Token);
    InsertLoginDetailsSwift(candid,Token);
}

function InsertLoginDetailsAndroid(candid,Token){
    try {
        window.appRef.InsertLoginDetails(candid,Token);
    } catch (error) {
        
    }
}

function InsertLoginDetailsSwift(candid,Token){
    try {
        webkit.messageHandlers.InsertLogin.postMessage(
            {
                "InsertLogin":candid,
                "Token":Token
            }
        );
    } catch (error) {
        
    }
}
