$(document).ready(function () {
  var carousel = $("#menu_carousel");
  carousel.owlCarousel({
    loop: false,
    margin: 16,
    center: false,
    dots: false,
    autoWidth: true,
    nav: false,
    startPosition: 0,
    responsiveClass: true,
    responsiveRefreshRate: true,
    responsive: {
      0: {
        items: 3
      },
      768: {
        items: 3
      }
    }
  });
});

function UpdateButtonClick(id) {
  document.getElementById(id).click();
}

function UpdateButtonClick1(id) {
if(id=='updateFormfreshreq' || id=='updateFormreq'){
  $("button").removeAttr('disabled');
}
  document.getElementById(id).click();
}

$(document).ready(function () {
  $('.cc_01, #offer_carousel').owlCarousel({
    loop: false,
    margin: 16,
    center: false,
    dots: false,
    autoWidth: true,
    nav: false,
    responsiveClass: true,
    responsiveRefreshRate: true,
    responsive: {
      0: {
        items: 3
      },
      768: {
        items: 3
      }
    }
  });
  var carousel = $("#menu_carousel");
  carousel.owlCarousel({
    loop: false,
    margin: 16,
    center: false,
    dots: false,
    autoWidth: true,
    nav: false,
    startPosition: 0,
    responsiveClass: true,
    responsiveRefreshRate: true,
    responsive: {
      0: {
        items: 3
      },
      768: {
        items: 3
      }
    }
  });

  $(document).ready(function () {
  $('.documentation').owlCarousel({
    loop: false,
    margin: 10,
    rewind: true,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      }
      
    }

  });
});

  $('.icon_camera').click(function () {
    $('.icon_frame').attr('src', 'images/id_pic.png');
    $('#upload').removeAttr('disabled');
  });

  $('#upload').click(function () {
    $('.status_cont').show().animate({
      'bottom': '0'
    });
  });

  $(document).on('click', '.id-page', function (e) {
    e.preventDefault();
    $('.id-page').removeClass('active');
    $(this).addClass('active');
    var coverId = $(this).attr('href');
    $('.id-cover').removeClass('active');
    $(coverId).addClass('active');
  });

  $('.material-input').each(function (index) {
    // in case the input is already filled..
    if ($.trim($(this).val()) !== '') {
      $(this).addClass('input_filled');
    }

    $(this).bind({
      focus: function () {
        $(this).addClass('input_filled');
      },
      blur: function () {
        if ($(this).val() === '') {
          $(this).removeClass('input_filled');
        }
      }
    });
  });

  $(document).on('click', '.otp-btn', function () {
    $(this).hide();
    $(this).parent().siblings('.otp-form').show();
  });

  // $(document).on('click', '.iciciButton', function () {
  //   $(this).parent().siblings('.otp-form').hide();
  // });

  // $(document).on('click', '.axisButton', function () {
  //   $(this).parent().siblings('.otp-form').hide();
  // });

  
    $(document).on('click', '.Reason', function () {
      //$(this).parent().parent().parent().siblings('.enterReason').show();
    });

    $(document).on('click', '#1', function () {
      $(this).parent().siblings('.enterReason').hide();
    });

    $(document).on('click', '#2', function () {
      $(this).parent().siblings('.enterReason').hide();
    });

    $(document).on('click', '#3', function () {
      $(this).parent().siblings('.enterReason').hide();
    });

    $(document).on('click', '#4', function () {
      $(this).parent().siblings('.enterReason').hide();
    });
  

  $(document).ready(function () {
    $("#hrtag").click(function () {
      $("#hr_modal").modal();
    });
  });

  $(document).on('click', '.transport_ul li', function () {
    $('.transport_ul li').removeClass('active');
    $(this).addClass('active');
    if ($(this).find('a').attr('class') == 'icon_car') {
      $('.mode_car').slideDown();
    } else {
      $('.mode_car').slideUp();
    }
  });

  //toggle the component with class accordion_body
  $(".accordion_head").click(function () {

    if ($(this).hasClass('active') == false) {
      $(".accordion_head,.accordion-item").removeClass('active');
      $(this).parent().addClass('active');
      $(this).addClass('active');
      if ($('.accordion_body').is(':visible')) {
        $(".accordion_body").slideUp(300);
      }
      if ($(this).next(".accordion_body").is(':visible')) {
        $(this).next(".accordion_body").slideUp(300);
      } else {
        $(this).next(".accordion_body").slideDown(300);
      }
    }
  });
});

function LoadDatePickerJs() {
  $('[data-toggle="datepicker"]').datepicker();

  $('.date-picker').on('pick.datepicker', function () {
    $(this).next('.label-float').css('top', '-20px')
  });
}

function LoadScrollJS() {
  //console.log('This is called from scrollJS typescript');
  $(document).ready(function () {
    $('.scrollbar-outer').scrollbar();
  })
}


// $('.customModal').on('shown.bs.modal', function (e) {
//   var modal_height = $(this).height();
//   var header_h = $(this).find('.modal-header').outerHeight();		
//   var scroll_h = modal_height - header_h;
//   $(this).find('.scrollbar-outer').css('height', scroll_h);
//   //$(this).find('.scrollbar-outer').scrollbar();
// });

function LoadScrollJSforHRRep() {
  //console.log('This is called from scrollJS typescript');
  $(document).ready(function () {
    $('.scrollbar-outer').scrollbar();
    $('.left-img-wrap .scrollbar-outer').css('height','60px');
  })
}

function LoadAccordianJs() {
  // $(document).ready(function () {

  //   $(".accordion_head").click(function () {
  //     debugger;
  //     if ($(this).hasClass('active') == false) {

  //       $(".accordion_head,.accordion-item").removeClass('active');

  //       $(this).parent().addClass('active');
  //       $(this).addClass('active');
  //       if ($('.accordion_body').is(':visible')) {
  //         $(".accordion_body").slideUp(300);

  //       }
  //       if ($(this).next(".accordion_body").is(':visible')) {
  //         $(this).next(".accordion_body").slideUp(300);

  //       } else {
  //         $(this).next(".accordion_body").slideDown(300);

  //       }
  //     }
  //   });

  // });

  $(document).on('click', '.accordion_head', function() {
   
    if ($(this).hasClass('active') == false) {

      $(".accordion_head,.accordion-item").removeClass('active');

      $(this).parent().addClass('active');
      $(this).addClass('active');
      if ($('.accordion_body').is(':visible')) {
        $(".accordion_body").slideUp(300);

      }
      if ($(this).next(".accordion_body").is(':visible')) {
        $(this).next(".accordion_body").slideUp(300);

      } else {
        $(this).next(".accordion_body").slideDown(300);

      }
    }
  });

}

function LoadOwlCarousel() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });
}
function LoadMailerCloseModalWebW4(){
  $(document).ready(function () {
    $('.tnc-modal').fadeOut(); 
  }); 
}


function LoadJsForDashBoardMobileCarousel() {
  $(document).ready(function () {
    $('.cc_01, #offer_carousel').owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: true,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });

    $('.owl-stage').css('margin-bottom','8px');
    $('.owl-stage').css('margin-top','5px');
    $('.owl-stage').css('margin-left','2px');
  });
}
function LoadOwlCarouselforpost(){
$(document).ready(function(){
	$('#actions_carousel, #offer_carousel').owlCarousel({
		loop: false,
		margin: 16,
		center: false,
		dots: false,
		autoWidth: true,
		nav:false,
		responsiveClass: true,
		responsiveRefreshRate: true,
		responsive : {
			0 : {
			 	items: 3
			},
			768 : {
			  	items: 3
			}
		}
  });
})
}
function LoadOwlCarouselForMenu() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });

}

function LoadOwlCarouselForMenuOnAcknowledgementUS() {
  $(document).ready(function () {
  var carousel = $("#menu_carousel");
  carousel.owlCarousel({
  loop: false,
  margin: 16,
  center: false,
  dots: false,
  autoWidth: true,
  nav: false,
  startPosition: 0,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive: {
  0: {
  items: 3
  },
  768: {
  items: 3
  }
  }
  });
  
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#ins').addClass('completed');
  $('#bank').addClass('completed');
  $('#policyus').addClass('selected');
  $('#policyus').addClass('item last');
  
  });
  
  } 

  function LoadOwlCarouselForMenuOnAcknowledgementMain(pre, ids, post,num,flg) {
    $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
    loop: false,
    margin: 16,
    center: false,
    dots: false,
    autoWidth: true,
    nav: false,
    startPosition: 0,
    responsiveClass: true,
    responsiveRefreshRate: true,
    responsive: {
    0: {
    items: 3
    },
    768: {
    items: 3
    }
    }
    });
    
    
    ChangeImageIcon(pre, ids, post,num,flg);


    });
    
    } 

  function LoadOwlCarouselForMenuOnAcknowledgementVanguard() {
    $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
    loop: false,
    margin: 16,
    center: false,
    dots: false,
    autoWidth: true,
    nav: false,
    startPosition: 0,
    responsiveClass: true,
    responsiveRefreshRate: true,
    responsive: {
    0: {
    items: 3
    },
    768: {
    items: 3
    }
    }
    });
    
    $('#start').addClass('completed'); 
    $('#ec').addClass('completed');
    $('#badge').addClass('completed');
    $('#residence').addClass('completed');
    $('#personal').addClass('completed');
    $('#ins').addClass('completed');
    $('#bank').addClass('completed');
    $('#i9').addClass('completed');
    $('#ssn').addClass('completed');
    $('#policyus').addClass('selected'); 
    $('#tfn').removeClass();
    $('#w').removeClass();
    $('#bank').removeClass();
    $('#bank').addClass('item last');
    
    
    });
    
    } 

  function LoadOwlCarouselForMenuOnAcknowledgementUSBPO() {
    $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
    loop: false,
    margin: 16,
    center: false,
    dots: false,
    autoWidth: true,
    nav: false,
    startPosition: 0,
    responsiveClass: true,
    responsiveRefreshRate: true,
    responsive: {
    0: {
    items: 3
    },
    768: {
    items: 3
    }
    }
    });

    $('#start').addClass('completed');
    $('#ec').addClass('completed');
    $('#badge').addClass('completed');
    $('#personal').addClass('completed');
    $('#dis').addClass('completed');
    $('#vet').addClass('completed');
    $('#residence').addClass('completed');
    $('#i9').addClass('completed');
    $('#ssn').addClass('completed');
    $('#card').addClass('completed');
    $('#bank').addClass('completed');
    $('#tfn').addClass('completed');
    $('#w').addClass('completed');
    $('#policyus').removeClass();
    $('#policyus').addClass('selected');
    $('#policyus').addClass('item last');
    $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
    
    });
    
    } 
    


function LoadOwlCarouselForMenuOnAcknowledgement() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });

    $('#ec').addClass('completed');
    $('#badge').addClass('completed');
    $('#personal').addClass('completed');
    $('#res').addClass('completed');
    $('#i9').addClass('completed');
    $('#bank').addClass('completed');
    $('#ssn').removeClass();
    $('#ssn').addClass('item active');
    $('#ssn').addClass('item last');


  });

}

function LoadOwlCarouselForMenuOnAcknowledgementUK() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });

    $('#start').addClass('completed'); 
    $('#ec').addClass('completed');
    $('#badge').addClass('completed');
    $('#residence').addClass('completed');
    $('#personal').addClass('completed');
    $('#taxuk').addClass('completed');
    $('#bank').addClass('completed');
    $('#insuranceuk').addClass('completed');
    $('#policyuk').addClass('selected');
    $('#policyuk').addClass('item last');

  });

}

//US pending mob start

function LoadOwlCarouselForMenu_US_Mobile() {
  $(document).ready(function () {
    var carousel = $("#menu_carouselUSMob");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });



}
function changePayrollIcon_MOB() {
  $('#pay').removeClass();
  $('#pay').addClass('selected');

  $('#ins').removeClass();
  $('#w4').removeClass();
  $('#policy').removeClass();
  $('#contact').removeClass();
  $('#address').removeClass();
  $('#disability').removeClass();
  $('#veteran').removeClass();
  $('#id').removeClass();
  $('#ss').removeClass();
  $('#bnk').removeClass();
  $('#bnk').addClass('item last');

}


function changeInsuranceIcon_MOB() {


  $('#pay').addClass('completed');
  $('#ins').removeClass();
  $('#ins').addClass('selected');
  $('#w4').removeClass();
  $('#policy').removeClass();
  $('#contact').removeClass();
  $('#address').removeClass();
  $('#disability').removeClass();
  $('#veteran').removeClass();
  $('#id').removeClass();
  $('#ss').removeClass();
  $('#bnk').removeClass();
  $('#bnk').addClass('item last');
  // $('#w').removeClass();
  // $('#w').addClass('item last');

}

function changeW4Icon_MOB() {


  $('#pay').addClass('completed');

  $('#ins').addClass('completed');
  $('#w4').removeClass();
  $('#w4').addClass('selected');
  $('#policy').removeClass();
  $('#contact').removeClass();
  $('#address').removeClass();
  $('#disability').removeClass();
  $('#veteran').removeClass();
  $('#id').removeClass();
  $('#ss').removeClass();
  $('#bnk').removeClass();
  $('#bnk').addClass('item last');
}
function ChangePolicyIcon_MOB() {

//alert('Changed icon called')

$('#pay').addClass('completed');

$('#ins').addClass('completed');
$('#w4').addClass('completed');
$('#policy').removeClass();
$('#policy').addClass('selected');
$('#contact').removeClass();
$('#address').removeClass();
$('#disability').removeClass();
$('#veteran').removeClass();
$('#id').removeClass();
$('#ss').removeClass();
$('#bnk').removeClass();
$('#bnk').addClass('item last');
 

}
function ChangeContactIcon_MOB() {

  //alert('Changed icon called')

  $('#pay').addClass('completed');

  $('#ins').addClass('completed');
  $('#w4').addClass('completed');
  $('#policy').addClass('completed');
  $('#contact').removeClass();
  $('#contact').addClass('selected');
  $('#address').removeClass();
  $('#disability').removeClass();
  $('#veteran').removeClass();
  $('#id').removeClass();
  $('#ss').removeClass();
  $('#bnk').removeClass();
  $('#bnk').addClass('item last');
  
  }
  function ChangeAddressIcon_MOB() {

    //alert('Changed icon called')
   
    $('#pay').addClass('completed');
  
    $('#ins').addClass('completed');
    $('#w4').addClass('completed');
    $('#policy').addClass('completed');
    $('#contact').addClass('completed');
    $('#address').removeClass();
    $('#address').addClass('selected');
    $('#disability').removeClass();
    $('#veteran').removeClass();
    $('#id').removeClass();
    $('#ss').removeClass();
    $('#bnk').removeClass();
    $('#bnk').addClass('item last');
    
    }
    function  chnageDisabilityIcon_MOb() {

      //alert('Changed icon called')
     
      $('#pay').addClass('completed');
    
      $('#ins').addClass('completed');
      $('#w4').addClass('completed');
      $('#policy').addClass('completed');
      $('#contact').addClass('completed');
      $('#address').addClass('completed');
      $('#veteran').addClass('completed');
      $('#disability').removeClass();
      $('#disability').addClass('selected');
      $('#id').removeClass();
      $('#ss').removeClass();
      $('#bnk').removeClass();
      $('#bnk').addClass('item last');
      
    }

    function  changeVeteranIcon_Mob() {

      //alert('Changed icon called')
     
      $('#pay').addClass('completed');
    
      $('#ins').addClass('completed');
      $('#w4').addClass('completed');
      $('#policy').addClass('completed');
      $('#contact').addClass('completed');
      $('#address').addClass('completed');
      $('#veteran').removeClass();
      $('#veteran').addClass('selected');
      $('#disability').removeClass();
      $('#id').removeClass();
      $('#ss').removeClass();
      $('#bnk').removeClass();
      $('#bnk').addClass('item last');
      
    }
    function ChangeIdIcon_MOB() {

      //alert('Changed icon called')

      $('#pay').addClass('selected');
    
      $('#ins').addClass('completed');
      $('#w4').addClass('completed');
      $('#policy').addClass('completed');
      $('#contact').addClass('completed');
      $('#address').addClass('completed');
      $('#disability').removeClass();
      $('#disability').addClass('completed');
      $('#veteran').addClass('completed');
      $('#id').removeClass();
      $('#id').addClass('selected');
      $('#ss').removeClass();
      $('#bnk').removeClass();
      $('#bnk').addClass('item last');
       
      
      }
      function ChangeSSIcon_MOB() {

        //alert('Changed icon called')
        $('#pay').removeClass();
        $('#pay').addClass('selected');
      
        $('#ins').addClass('completed');
        $('#w4').addClass('completed');
        $('#policy').addClass('completed');
        $('#contact').addClass('completed');
        $('#address').addClass('completed');
        $('#disability').addClass('completed');
        $('#veteran').addClass('completed');
        $('#id').addClass('completed');
        $('#ss').removeClass();
        $('#ss').addClass('selected');
        $('#bnk').removeClass();
        $('#bnk').addClass('item last');
         
        
        }
        function ChangeBankIcon_MOB() {

          //alert('Changed icon called')
          $('#pay').addClass('completed');
        
          $('#ins').addClass('completed');
          $('#w4').addClass('completed');
          $('#policy').addClass('completed');
          $('#contact').addClass('completed');
          $('#address').addClass('completed');
          $('#disability').addClass('completed');
          $('#veteran').addClass('completed');
          $('#id').addClass('completed');
          $('#ss').addClass('completed');
          $('#bnk').removeClass();
          $('#bnk').addClass('selected');
          $('#bnk').addClass('item last');
           
          
          }
 // US pending mob ends       

function LoadOwlCarouselForMenuOnAcknowledgementIN() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });

    var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#nomi').addClass('completed');
  $('#medi').addClass('completed');
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  $('#vaccine').addClass('completed');
  $('#id').addClass('completed');
  $('#stat').removeClass();
  $('#stat').addClass('selected');
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 16); 
  }
  else {
    owl.trigger('to.owl.carousel', 12);
  }
  

  });

}
function LoadOwlCarouselForMenuOnAcknowledgementUS() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });

    $('#start').addClass('completed'); 
    $('#ec').addClass('completed');
    $('#badge').addClass('completed');
    $('#residence').addClass('completed');
    $('#personal').addClass('completed');
    $('#pay').addClass('completed');
    $('#ins').addClass('completed');
    $('#policyus').addClass('selected');
    $('#policyus').addClass('item last');

  });

}

$(document).ready(function () {
  // $('.cc_01, #offer_carousel').owlCarousel({
  // 	loop: false,
  // 	margin: 16,
  // 	center: false,
  // 	dots: false,
  // 	autoWidth: true,
  // 	nav:false,
  // 	responsiveClass: true,
  // 	responsiveRefreshRate: true,
  // 	responsive : {
  // 		0 : {
  // 		 	items: 3
  // 		},
  // 		768 : {
  // 		  	items: 3
  // 		}
  // 	}
  // });

  $('.camera_default').click(function () {
    $('.icon_frame').attr('src', 'assets/images/id_pic.png');
    $('#upload').removeAttr('disabled');
  });

  $('#upload').click(function () {
    $('.status_cont').show().animate({
      'bottom': '0'
    });
  });

  $(document).on('click', '.id-page', function (e) {
    e.preventDefault();
    $('.id-page').removeClass('active');
    $(this).addClass('active');
    var coverId = $(this).attr('href');
    $('.id-cover').removeClass('active');
    $(coverId).addClass('active');
  });

  $('.material-input').each(function (index) {
    // in case the input is already filled..
    if ($.trim($(this).val()) !== '') {
      $(this).addClass('input_filled');
    }

    $(this).bind({
      focus: function () {
        $(this).addClass('input_filled');
      },
      blur: function () {
        if ($(this).val() === '') {
          $(this).removeClass('input_filled');
        }
      }
    });
  });

  $(document).on('click', '.otp-btn', function () {
    $(this).hide();
    $(this).parent().siblings('.otp-form').show();
  });
});

function skipAddressImage() {
  $('#residence').removeClass();
}

function ChangeStartScreenImage() {
  $('#start').removeClass();
  $('#start').addClass('selected');
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#personal').removeClass(); 
  $('#dis').removeClass();
  $('#vet').removeClass();
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
  
}


function ChangeIdBadgeImage() {
  $('#start').addClass('completed');
  $('#ec').removeClass();
  $('#ec').addClass('selected');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#dis').removeClass();
  $('#vet').removeClass();
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');  
}

function ChangeEmergencyImage() {
  $('#start').addClass('completed');
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#personal').removeClass();
  $('#dis').removeClass();
  $('#vet').removeClass();
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}

function ChangePersonalImage() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('selected');
  $('#dis').removeClass();
  $('#vet').removeClass();
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
}

function ChangeAddressImage() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#dis').addClass('completed');
  $('#vet').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}
function ChangeDisabilityImage() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#dis').removeClass();
  $('#dis').addClass('selected');
  $('#vet').removeClass();
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
 }
 function ChangeDisabilityImage1(){
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('completed');
  $('#dis').removeClass();
  $('#dis').addClass('selected');
  $('#vet').removeClass();
  $('#residence').removeClass();
  $('#ins').removeClass();
  $('#pay').removeClass();
  $('#policy').removeClass();
  $('#w4').removeClass();  

  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
 }

 function veteranSectionImage(){
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#dis').addClass('completed');
  $('#vet').removeClass();
  $('#vet').addClass('selected');
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
 }
 function veteranSectionImage1(){
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('completed');
  $('#dis').removeClass();
  $('#dis').addClass('completed');
  $('#vet').removeClass();
  $('#vet').addClass('selected');
  $('#residence').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
 }


function Changei9Image() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#dis').addClass('completed');
  $('#vet').addClass('completed');
  $('#i9').removeClass();
  $('#i9').addClass('selected');
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [5, 1]);
}

function ChangeSsnImage() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').removeClass();
  $('#ssn').addClass('selected');
  $('#card').removeClass();
  $('#policyus').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');  

}

function ChangeBankImage() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').addClass('completed');
  $('#policyus').addClass('completed');
  $('#tfn').addClass('completed');
  $('#w').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#bank').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
}
function ChangePolicyIconForBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').addClass('completed');
  $('#bank').addClass('completed');
  $('#tfn').addClass('completed');
  $('#w').addClass('completed');
  $('#policyus').removeClass();
  $('#policyus').addClass('selected');
  $('#policyus').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
}

function ChangeStartScreenImageBPO() {
  $('#start').removeClass();
  $('#start').addClass('selected');
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#personal').removeClass();
  $('#residence').removeClass();
  $('#vet').removeClass();
  $('#dis').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  
}


function ChangeIdBadgeImageBPO() {
  $('#start').addClass('completed');
  $('#ec').removeClass();
  $('#ec').addClass('selected');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#residence').removeClass();
  $('#vet').removeClass();
  $('#dis').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass(); 
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
}

function ChangeEmergencyImageBPO() {
  $('#start').addClass('completed');
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#personal').removeClass();
  $('#residence').removeClass();
  $('#vet').removeClass();
  $('#dis').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
}

function ChangePersonalImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('selected');
  $('#residence').removeClass();
  $('#vet').removeClass();
  $('#dis').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
}

function ChangeAddressImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
}

function ChangeVeteranImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').removeClass();
  $('#vet').addClass('selected');
  $('#dis').removeClass();
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
}


function ChangeDisabilityImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').removeClass();
  $('#dis').addClass('selected');
  $('#i9').removeClass();
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
}

function Changei9ImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').removeClass();
  $('#i9').addClass('selected');
  $('#ssn').removeClass();
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [5, 1]);
}

function ChangeSsnImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').removeClass();
  $('#ssn').addClass('selected');
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');

}

function ChangeBankImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
}

function ChangeW4ImageVanguardBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').addClass('completed');
  $('#bank').addClass('completed');
  $('#w').removeClass();
  $('#w').addClass('selected');
  $('#tfn').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
}

function ChangeTaxImageVanguardBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#bank').addClass('completed');
  $('#w').addClass('completed');
  $('#tfn').removeClass();
  $('#tfn').addClass('selected');
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
}

function ChangeCardImageBPO() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').removeClass();
  $('#card').addClass('selected');
  $('#bank').removeClass();
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [7, 1]);
}

function ChangeCardImageMcCamish() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#vet').addClass('completed');
  $('#dis').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#policyus').removeClass();
  $('#policyus').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [7, 1]);
}

function ChangeCardImage() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').removeClass();
  $('#card').addClass('selected');
  $('#bank').removeClass();
  $('#bank').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [7, 1]);
}
function ChangePolicyIconForVanguard() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').addClass('completed');
  $('#bank').addClass('completed');
  $('#policyus').removeClass();
  $('#policyus').addClass('selected');
  $('#tfn').removeClass();
  $('#w').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [7, 1]);
}
function ChangeW4ImageVanguard() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#card').addClass('completed');
  $('#bank').addClass('completed');
  $('#policyus').addClass('completed');
  $('#w').removeClass();
  $('#w').addClass('selected');
  $('#tfn').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}

function ChangeTaxImageVanguard() {
  $('#start').addClass('completed');
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#i9').addClass('completed');
  $('#ssn').addClass('completed');
  $('#policyus').addClass('completed');
  $('#w').addClass('completed');
  $('#tfn').removeClass();
  $('#tfn').addClass('selected');
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}


function ChangeFamilyImageSWZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#family').removeClass();
  $('#family').addClass('selected');
  $('#workpermit').removeClass();
  $('#passport').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}

function ChangeIdBadgeImageSWZ() {
  $('#ec').removeClass();
  $('#ec').addClass('selected');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#family').removeClass();
  $('#residence').removeClass();
  $('#workpermit').removeClass();
  $('#passport').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}

function ChangeEmergencyImageSWZ() {
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#personal').removeClass();
  $('#family').removeClass();
  $('#residence').removeClass();
  $('#workpermit').removeClass();
  $('#passport').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}

function ChangeImageIcon(pre, ids, post,num,flg)
{
  //alert('Insie image change method');
  for (let i of pre)
  {
    $('#'+i).addClass('completed');
  }
  $('#'+ids).removeClass();
  $('#'+ids).addClass('selected');
  for (let i of post)
  {
    $('#'+i).removeClass();
  }
  if(flg == 'Y')
  {
    $('#tfn').addClass('item last');
  }
  else
  {
  $('#policyuk').addClass('item last');
  }
  if(num > 4){
    $("#menu_carousel").trigger("to.owl.carousel", [num, 1]);
  }
}

function ChangeAddressImageSWZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#workpermit').removeClass();
  $('#personal').removeClass();
  $('#family').removeClass();
  $('#passport').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}

function ChangePersonalImageSWZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('selected');
  $('#family').removeClass();
  $('#workpermit').removeClass();
  $('#passport').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}
function ChangeWorkPermitImageSWZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#family').addClass('completed');
  $('#workpermit').removeClass();
  $('#workpermit').addClass('selected');
  $('#passport').removeClass();
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}
function ChangePassportImageSWZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#family').addClass('completed');
  $('#workpermit').addClass('completed');
  $('#passport').removeClass();
  $('#passport').addClass('selected');
  $('#bank').removeClass();
  $('#bank').addClass('item last');
}
function ChangeBankImageSWZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#family').addClass('completed');
  $('#workpermit').addClass('completed');
  $('#passport').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#bank').focus();
  $('#bank').addClass('item last');
}

function ChangeStartScreenImageUK() {
  $('#start').removeClass();
  $('#start').addClass('selected');  
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#personal').removeClass();
  $('#residence').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
}


function ChangeEmergencyImageUK() {
  $('#start').addClass('completed');  
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#ec').removeClass();
  $('#residence').removeClass();
  $('#personal').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
}

function ChangeIdBadgeImageUK() {
  $('#start').addClass('completed'); 
  $('#badge').addClass('completed');
  $('#ec').removeClass();
  $('#ec').addClass('selected');
  $('#residence').removeClass();
  $('#personal').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
}

function ChangeAddressImageUK() {
  $('#start').addClass('completed'); 
  $('#badge').addClass('completed');
  $('#ec').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#personal').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
}

function ChangePersonalImageUK() {
  $('#start').addClass('completed'); 
  $('#badge').addClass('completed');
  $('#ec').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('selected');
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [4, 2]);
}

function ChangeTaxImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').removeClass();
  $('#taxuk').addClass('selected');
  $('#bank').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
}

function ChangeBankImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangeInsuranceImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').removeClass();
  $('#insuranceuk').addClass('selected');
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangeResiPermitImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('selected');
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangePassportImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('completed');
  $('#passportuk').addClass('selected');
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangeJoiningDocsImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('completed');
  $('#passportuk').addClass('completed');
  $('#joiningdocsuk').addClass('selected');
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangePolicyImageUK() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('completed');
  $('#passportuk').addClass('completed');
  $('#joiningdocsuk').addClass('completed');
  $('#policyuk').removeClass();
  $('#policyuk').focus();
  $('#policyuk').addClass('selected');
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}

//UK_Mobile_Start

function LoadOwlCarouselForMenu_UK_Mobile() {
  $(document).ready(function () {
    var carousel = $("#menu_carouselUkMob");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });



}


function ChangeEmergencyImageUK_Mobile() {
  $('#start').addClass('completed');  
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#ec').removeClass();
  $('#residence').removeClass();
  $('#personal').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
}

function ChangeIdBadgeImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#badge').addClass('completed');
  $('#ec').removeClass();
  $('#ec').addClass('selected');
  $('#residence').removeClass();
  $('#personal').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
//  $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
}


function ChangeAddressImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#badge').addClass('completed');
  $('#ec').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#personal').removeClass();
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
  // $("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
}

function ChangePersonalImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#badge').addClass('completed');
  $('#ec').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('selected');
  $('#taxuk').removeClass();
  $('#bank').removeClass();
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
  // $("#menu_carousel").trigger("to.owl.carousel", [4, 2]);
}

function ChangeTaxImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').removeClass();
  $('#taxuk').addClass('selected');
  $('#bank').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
}

function ChangeBankImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#insuranceuk').removeClass();
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangeInsuranceImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').removeClass();
  $('#insuranceuk').addClass('selected');
  $('#resipermituk').removeClass();
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
  //$("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangeresipermitukImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('selected');
  $('#passportuk').removeClass();
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangePassportImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('completed');
  $('#passportuk').addClass('selected');
  $('#joiningdocsuk').removeClass();
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangeJoiningDocsImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('completed');
  $('#passportuk').addClass('completed');
  $('#joiningdocsuk').addClass('selected');
  $('#policyuk').removeClass();
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
function ChangePolicyImageUK_Mobile() {
  $('#start').addClass('completed'); 
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#taxuk').addClass('completed');
  $('#bank').addClass('completed');
  $('#insuranceuk').addClass('completed');
  $('#resipermituk').addClass('completed');
  $('#passportuk').addClass('completed');
  $('#joiningdocsuk').addClass('completed');
  $('#policyuk').removeClass();
  $('#policyuk').focus();
  $('#policyuk').addClass('selected');
  $('#policyuk').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [1, 1]);
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
}
// UK_Mobile_end

//INDIA ICONS CHANGE ON MOBILE
function LoadStyleForIconsIndia(id, emer, bnk, basic, fam, contact , edu, exp, passport, gap, 
  disability, nsr, pan, aadhar, acc, med, ach, nats,statutory) {
  if (id == "Y") {
    $('#badge').closest('.owl-item').remove();
  }
  if (emer == "Y") {
    $('#emer').closest('.owl-item').remove();
  }
  if (bnk == "Y") {
    $('#bank').closest('.owl-item').remove();
  }
  if (basic == "Y") {
    $('#basic').closest('.owl-item').remove();
  }
  if (fam == "Y") {
    $('#fam').closest('.owl-item').remove();
  }
  if (contact == "Y") {
    $('#contact').closest('.owl-item').remove();
  }  
  if (edu == "Y") {
    $('#edu').closest('.owl-item').remove();
  }
  if (exp == "Y") {
    $('#exp').closest('.owl-item').remove();
  }
  if (passport == "Y") {
    $('#pass').closest('.owl-item').remove();
  }
  if (gap == "Y") {
    $('#gap').closest('.owl-item').remove();
  }
  if (disability == "Y") {
    $('#disability').closest('.owl-item').remove();
  }  
  if (nsr == "Y") {
    $('#nsr').closest('.owl-item').remove();
  }
  if (pan == "Y") {
    $('#pan').closest('.owl-item').remove();
  }
  if (aadhar == "Y") {
    $('#aadhar').closest('.owl-item').remove();
  }
  if (acc == "Y") {
    $('#acc').closest('.owl-item').remove();
  }
  if (med == "Y") {
    $('#med').closest('.owl-item').remove();
  }
  if (ach == "Y") {
    $('#ach').closest('.owl-item').remove();
  }
  // if (docs == "Y") {
  //   $('#docs').closest('.owl-item').remove();
  // }
  if (nats == "Y") {
    $('#nats').closest('.owl-item').remove();
  }
  if (statutory == "Y") {
    $('#statutory').closest('.owl-item').remove();
  }
}

function ChgstartIN() {
  $('#start').removeClass();
  $('#start').addClass('selected');
  $('#id-card').removeClass();
  $('#per').removeClass();
  $('#fam').removeClass();
  $('#dis').removeClass();
  $('#edu').removeClass();
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}
function ChgidcardIN() {
  $('#start').addClass('completed');
  $('#id-card').removeClass();
  $('#id-card').addClass('selected');
  $('#per').removeClass();
  $('#fam').removeClass();
  $('#dis').removeClass();
  $('#edu').removeClass();
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}

function ChgperIN() {
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').removeClass();
  $('#per').addClass('selected');
  $('#fam').removeClass();
  $('#dis').removeClass();
  $('#edu').removeClass();
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}

function ChgfamIN() {
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').removeClass();
  $('#fam').addClass('selected');
  $('#dis').removeClass();
  $('#edu').removeClass();
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}

function fadein() {
  $('.headerInner_bg').fadeIn();
  $('.tnc-modal').fadeOut();
}

function ChgdisIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').removeClass();
  $('#dis').addClass('selected');
  $('#edu').removeClass();
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 1); 
  }
  

}
function ChgresiIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  // $('#acco').addClass('completed');
  $('#resi').removeClass();
  $('#resi').addClass('selected');
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [10, 1]);
  //$("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
  //$("#web_carousel").trigger("to.owl.carousel", [5,1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 11); 
  }
  else {
    owl.trigger('to.owl.carousel', 7);
  }
  
}
function ChgeduIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed');
  $('#edu').removeClass();
  $('#edu').addClass('selected');
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 2);
  }
  else {
    owl.trigger('to.owl.carousel', 0);
  }
  
}

function ChgwrkexIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').removeClass();
  $('#wrkex').addClass('selected');
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  //$('#cc').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [4, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 5); 
  }
  else {
    owl.trigger('to.owl.carousel', 1); 
  }
 
}
function ChggapIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').removeClass();
  $('#gap').addClass('selected');
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  //$('#cc').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 6); 
  }
  else {
    owl.trigger('to.owl.carousel', 2);
  }
   
}
function ChgpassIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').removeClass();
  $('#pass').addClass('selected');
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#docs').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 8);
  }
  else {
    owl.trigger('to.owl.carousel', 4);
  }
   
}
function ChgpanIN(){
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').removeClass();
  $('#pan').addClass('selected');
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 9);
  }
  else {
    owl.trigger('to.owl.carousel', 5);
  }
   
}
function ChgnsrIN(){
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').removeClass();
  $('#nsr').addClass('selected');
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 7);
  }
  else {
    owl.trigger('to.owl.carousel', 3);
  }
   
}
function ChgadharIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').removeClass();
  $('#adhar').addClass('selected');
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#medi').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#docs').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 10);
  }
  else {
    owl.trigger('to.owl.carousel', 6);
  }
   
}
function ChgaccoIN(){
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed');
  $('#resi').addClass('completed');
  $('#edu').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#nsr').addClass('completed');
  $('#adhar').addClass('completed');
  $('#acco').removeClass();
  $('#acco').addClass('selected');
  $('#achieve').removeClass();
  $('#aspi').removeClass();
  $('#medi').removeClass();
  $('#othr').removeClass();
  $('#bnk').removeClass();
  //$('#cc').removeClass();
  $('#nomi').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  // $('#resi').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [10, 1]);
  
}
function ChgachieveIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').removeClass();
  $('#achieve').addClass('selected');
  $('#aspi').removeClass();
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#docs').removeClass();
  $('#id').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 3);
  }
  else {
    owl.trigger('to.owl.carousel', 0);
  }
  
}

function ChgaspiIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').removeClass();
  $('#aspi').addClass('selected');
  $('#wrkex').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#adhar').removeClass();
  $('#othr').removeClass();
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 4);
  }
  else {
    owl.trigger('to.owl.carousel', 0);
  }
  
}
function ChgmediIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').removeClass();
  $('#medi').addClass('selected');
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [12, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 14); 
  }
  else {
    owl.trigger('to.owl.carousel', 10);
  }
  
}
function ChgothrIN() {
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').removeClass();
  $('#othr').addClass('selected');
  $('#resi').removeClass();
  $('#bnk').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  
}
function ChgbnkIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  // $('#acco').addClass('completed');
  $('#bnk').removeClass();
  $('#bnk').addClass('selected');
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#medi').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 12); 
  }
  else {
    owl.trigger('to.owl.carousel', 8);
  }
  
}

function ChgdocsIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  // $('#acco').addClass('completed');
  $('#docs').removeClass();
  $('#docs').addClass('selected');
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').addClass('completed');
  //$('#cc').removeClass();
  $('#id').addClass('completed');
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 16); 
  }
  else {
    owl.trigger('to.owl.carousel', 12);
  }
  
}

function ChgnatsIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#docs').addClass('completed');
  // $('#acco').addClass('completed');
  $('#nats').removeClass();
  $('#nats').addClass('selected');
  $('#vaccine').removeClass();
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').addClass('completed');
  //$('#cc').removeClass();
  $('#id').addClass('completed');
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 15); 
  }
  else {
    owl.trigger('to.owl.carousel', 11);
  }
  
}


function ChgccIN() {
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed');
  $('#resi').addClass('completed');
  $('#edu').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#nsr').addClass('completed');
  $('#adhar').addClass('completed');
  $('#acco').addClass('completed');
  $('#achieve').addClass('completed');
  $('#medi').addClass('completed');
  $('#othr').addClass('completed');
  $('#bnk').addClass('completed');
  //$('#cc').removeClass();
  //$('#cc').addClass('selected');
  $('#nomi').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
 
}

function ChgnomiIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  // $('#acco').addClass('completed');
  $('#nomi').removeClass();
  $('#nomi').addClass('selected');
  //$('#acco').removeClass();
  $('#medi').removeClass();
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [12, 1]);
  //$("#menu_carousel").trigger("to.owl.carousel", [16, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 13); 
  }
  else {
    owl.trigger('to.owl.carousel', 9);
  }
  
}

function ChgidIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#nomi').addClass('completed');
  // $('#acco').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').addClass('completed');
  //$('#cc').removeClass();
  $('#id').removeClass();
  $('#id').addClass('selected');
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 15); 
  }
  else {
    owl.trigger('to.owl.carousel', 11);
  }
  
}
// function ChgvaccineIN() {
//   var h= $( window ).width();
//   var owl = $('.owl-carousel');
//     owl.owlCarousel({
//         items:6,
//         loop:true,
//         margin:10,
//     })
//   $('#start').addClass('completed');
//   $('#id-card').addClass('completed');
//   $('#per').addClass('completed');
//   $('#fam').addClass('completed');
//   $('#dis').addClass('completed')
//   $('#edu').addClass('completed');
//   $('#achieve').addClass('completed');
//   $('#aspi').addClass('completed');
//   $('#wrkex').addClass('completed');
//   $('#gap').addClass('completed');
//   $('#nsr').addClass('completed');
//   $('#pass').addClass('completed');
//   $('#pan').addClass('completed');
//   $('#adhar').addClass('completed');
//   $('#othr').addClass('completed');
//   $('#resi').addClass('completed');
//   $('#bnk').addClass('completed');
//   $('#nomi').addClass('completed');
//   $('#vaccine').addClass('completed');
//   //$('#acco').removeClass();
//   $('#medi').addClass('completed');
//   //$('#cc').removeClass();
//   $('#docs').addClass('completed');
//   $('#nats').addClass('completed');
//   $('#id').addClass('completed');
//   $('#stat').removeClass();
//   $('#stat').addClass('selected');
//   $('#auth').removeClass();
//   $('#auth').addClass('item last');
//   //$("#menu_carousel").trigger("to.owl.carousel", [18, 1]);
//   if(h<=599) {
//     owl.trigger('to.owl.carousel', 16); 
//   }
//   else {
//     owl.trigger('to.owl.carousel', 12);
//   }
  
// }
function ChgvaccineIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  // $('#acco').addClass('completed');
  $('#vaccine').removeClass();
  $('#vaccine').addClass('selected');
  
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').addClass('completed');
  //$('#cc').removeClass();
  $('#id').addClass('completed');
  $('#stat').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 15); 
  }
  else {
    owl.trigger('to.owl.carousel', 11);
  }
  
}



function ChgstatIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#nomi').addClass('completed');
  // $('#acco').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').addClass('completed');
  //$('#cc').removeClass();
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  $('#vaccine').addClass('completed');
  $('#id').addClass('completed');
  $('#stat').removeClass();
  $('#stat').addClass('selected');
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [18, 1]);
  if(h<=599) {
    owl.trigger('to.owl.carousel', 16); 
  }
  else {
    owl.trigger('to.owl.carousel', 12);
  }
  
}

function ChgauthIN() {
  var h= $( window ).width();
  var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    })
  $('#start').addClass('completed');
  $('#id-card').addClass('completed');
  $('#per').addClass('completed');
  $('#fam').addClass('completed');
  $('#dis').addClass('completed')
  $('#edu').addClass('completed');
  $('#achieve').addClass('completed');
  $('#aspi').addClass('completed');
  $('#wrkex').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#adhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#resi').addClass('completed');
  $('#bnk').addClass('completed');
  $('#nomi').addClass('completed');
  // $('#acco').addClass('completed');
  //$('#acco').removeClass();
  $('#medi').addClass('completed');
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  $('#vaccine').addClass('completed');
  //$('#cc').removeClass();
  $('#id').addClass('completed');
  $('#stat').addClass('completed');
  $('#auth').removeClass();
  $('#auth').addClass('selected');
  $('#auth').addClass('item last');
  if(h<=599) {
    owl.trigger('to.owl.carousel', 17);
  }
  else {
    owl.trigger('to.owl.carousel', 13);
  }
   
}


//IN Pending mob start

function LoadOwlCarouselForMenu_IN_Mobile() {
  $(document).ready(function () {
    var carousel = $("#menu_carouselINMob");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });



}

function ChgstartIN_Mob() {
  $('#start').removeClass();
  $('#start').addClass('selected');
  $('#badge').removeClass();
  $('#basic').removeClass();
  $('#fam').removeClass();
  $('#disability').removeClass();
  $('#edu').removeClass();
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}
function ChgidcardIN_Mob() {
  $('#start').addClass('completed');
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#basic').removeClass();
  $('#fam').removeClass();
  $('#disability').removeClass();
  $('#edu').removeClass();
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}

function ChgperIN_Mob() {
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').removeClass();
  $('#basic').addClass('selected');
  $('#fam').removeClass();
  $('#disability').removeClass();
  $('#edu').removeClass();
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}

function ChgfamIN_Mob() {
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').removeClass();
  $('#fam').addClass('selected');
  $('#disability').removeClass();
  $('#edu').removeClass();
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
}

function fadein() {
  $('.headerInner_bg').fadeIn();
  $('.tnc-modal').fadeOut();
}

function ChgdisIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').removeClass();
  $('#disability').addClass('selected');
  $('#edu').removeClass();
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 1); 
  // }
  

}
function ChgresiIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  // $('#acco').addClass('completed');
  $('#contact').removeClass();
  $('#contact').addClass('selected');
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [10, 1]);
  //$("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
  //$("#web_carousel").trigger("to.owl.carousel", [5,1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 11); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 7);
  // }
  
}
function ChgeduIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed');
  $('#edu').removeClass();
  $('#edu').addClass('selected');
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 2);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 0);
  // }
  
}

function ChgwrkexIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').removeClass();
  $('#exp').addClass('selected');
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  //$('#cc').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [4, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 5); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 1); 
  // }
 
}
function ChggapIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').removeClass();
  $('#gap').addClass('selected');
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  //$('#cc').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 6); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 2);
  // }
   
}
function ChgpassIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').removeClass();
  $('#pass').addClass('selected');
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#docs').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [6, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 8);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 4);
  // }
   
}
function ChgpanIN_Mob(){
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').removeClass();
  $('#pan').addClass('selected');
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 9);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 5);
  // }
   
}
function ChgnsrIN_Mob(){
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').removeClass();
  $('#nsr').addClass('selected');
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 7);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 3);
  // }
   
}
function ChgadharIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').removeClass();
  $('#aadhar').addClass('selected');
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  // $('#acco').removeClass();
  $('#med').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#docs').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [8, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 10);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 6);
  // }
   
}
function ChgaccoIN_Mob(){
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed');
  $('#contact').addClass('completed');
  $('#edu').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#nsr').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#acco').removeClass();
  $('#acco').addClass('selected');
  $('#ach').removeClass();
  $('#aspi').removeClass();
  $('#med').removeClass();
  $('#othr').removeClass();
  $('#bank').removeClass();
  //$('#cc').removeClass();
  $('#nomi').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  // $('#contact').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [10, 1]);
  
}
function ChgachieveIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').removeClass();
  $('#ach').addClass('selected');
  $('#aspi').removeClass();
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  //$('#cc').removeClass();
  $('#docs').removeClass();
  $('#emer').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 3);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 0);
  // }
  
}

function ChgaspiIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').removeClass();
  $('#aspi').addClass('selected');
  $('#exp').removeClass();
  $('#gap').removeClass();
  $('#nsr').removeClass();
  $('#pass').removeClass();
  $('#pan').removeClass();
  $('#aadhar').removeClass();
  $('#othr').removeClass();
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 4);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 0);
  // }
  
}
function ChgmediIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#med').removeClass();
  $('#med').addClass('selected');
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [12, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 14); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 10);
  // }
  
}
function ChgothrIN_Mob() {
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').removeClass();
  $('#othr').addClass('selected');
  $('#contact').removeClass();
  $('#bank').removeClass();
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  $('#auth').addClass('item last');
  
}
function ChgbnkIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  // $('#acco').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#nomi').removeClass();
  //$('#acco').removeClass();
  $('#med').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 12); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 8);
  // }
  
}

function ChgdocsIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  // $('#acco').addClass('completed');
  $('#docs').removeClass();
  $('#docs').addClass('selected');
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#med').addClass('completed');
  //$('#cc').removeClass();
  $('#emer').addClass('completed');
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 16); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 12);
  // }
  
}

function ChgnatsIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  $('#docs').addClass('completed');
  // $('#acco').addClass('completed');
  $('#nats').removeClass();
  $('#nats').addClass('selected');
  $('#vaccine').removeClass();
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#med').addClass('completed');
  //$('#cc').removeClass();
  $('#emer').addClass('completed');
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 15); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 11);
  // }
  
}


function ChgccIN_Mob() {
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed');
  $('#contact').addClass('completed');
  $('#edu').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#nsr').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#acco').addClass('completed');
  $('#ach').addClass('completed');
  $('#med').addClass('completed');
  $('#othr').addClass('completed');
  $('#bank').addClass('completed');
  //$('#cc').removeClass();
  //$('#cc').addClass('selected');
  $('#nomi').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
 
}

function ChgnomiIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  // $('#acco').addClass('completed');
  $('#nomi').removeClass();
  $('#nomi').addClass('selected');
  //$('#acco').removeClass();
  $('#med').removeClass();
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [12, 1]);
  //$("#menu_carousel").trigger("to.owl.carousel", [16, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 13); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 9);
  // }
  
}

function ChgidIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  $('#nomi').addClass('completed');
  // $('#acco').addClass('completed');
  //$('#acco').removeClass();
  $('#med').addClass('completed');
  //$('#cc').removeClass();
  $('#emer').removeClass();
  $('#emer').addClass('selected');
  $('#docs').removeClass();
  $('#nats').removeClass();
  $('#vaccine').removeClass();
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 15); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 11);
  // }
  
}

function ChgvaccineIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  // $('#acco').addClass('completed');
  $('#vaccine').removeClass();
  $('#vaccine').addClass('selected');
  
  $('#nomi').addClass('completed');
  //$('#acco').removeClass();
  $('#med').addClass('completed');
  //$('#cc').removeClass();
  $('#emer').addClass('completed');
  $('#statutory').removeClass();
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [14, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 15); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 11);
  // }
  
}



function ChgstatIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  $('#nomi').addClass('completed');
  // $('#acco').addClass('completed');
  //$('#acco').removeClass();
  $('#med').addClass('completed');
  //$('#cc').removeClass();
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  $('#vaccine').addClass('completed');
  $('#emer').addClass('completed');
  $('#statutory').removeClass();
  $('#statutory').addClass('selected');
  $('#auth').removeClass();
  $('#auth').addClass('item last');
  //$("#menu_carousel").trigger("to.owl.carousel", [18, 1]);
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 16); 
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 12);
  // }
  
}

function ChgauthIN_Mob() {
  // var h= $( window ).width();
  // var owl = $('.owl-carousel');
  //   owl.owlCarousel({
  //       items:6,
  //       loop:true,
  //       margin:10,
  //   })
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#basic').addClass('completed');
  $('#fam').addClass('completed');
  $('#disability').addClass('completed')
  $('#edu').addClass('completed');
  $('#ach').addClass('completed');
  $('#aspi').addClass('completed');
  $('#exp').addClass('completed');
  $('#gap').addClass('completed');
  $('#nsr').addClass('completed');
  $('#pass').addClass('completed');
  $('#pan').addClass('completed');
  $('#aadhar').addClass('completed');
  $('#othr').addClass('completed');
  $('#contact').addClass('completed');
  $('#bank').addClass('completed');
  $('#nomi').addClass('completed');
  // $('#acco').addClass('completed');
  //$('#acco').removeClass();
  $('#med').addClass('completed');
  $('#docs').addClass('completed');
  $('#nats').addClass('completed');
  $('#vaccine').addClass('completed');
  //$('#cc').removeClass();
  $('#emer').addClass('completed');
  $('#statutory').addClass('completed');
  $('#auth').removeClass();
  $('#auth').addClass('selected');
  $('#auth').addClass('item last');
  // if(h<=599) {
  //   owl.trigger('to.owl.carousel', 17);
  // }
  // else {
  //   owl.trigger('to.owl.carousel', 13);
  // }
   
}
//IN pending mob end

function ChgreloIN() {
  $('#relo').removeClass();
  $('#relo').addClass('selected');
  $('#travel').removeClass();
  $('#acco').removeClass();
  $('#food').removeClass();
}

function ChgtravelIN() {
  $('#relo').addClass('completed');
  $('#travel').removeClass();
  $('#travel').addClass('selected');
  $('#acco').removeClass();
  $('#food').removeClass();
}

// function ChgaccoIN() {
//   $('#relo').addClass('completed');
//   $('#travel').addClass('completed');
//   $('#acco').removeClass();
//   $('#acco').addClass('selected');
//   $('#food').removeClass();
// }

function ChgfoodIN() {
  $('#relo').addClass('completed');
  $('#travel').addClass('completed');
  $('#acco').addClass('completed');
  $('#food').removeClass();
  $('#food').addClass('selected');
}

function LoadStartStep1Modal() {
  $(document).ready(function () {
    $('#start_modal').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadStartStep2NewGeoModalMobile() {
  // $('#relocationOnboarding').show();
  // $('#relocationOnboarding').removeClass();
  // $('#relocationOnboarding').addClass('item last');

  $(document).ready(function () {
    $('#start_modal').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');

    $('#relocationOnboarding').show();
  $('#relocationOnboarding').removeClass();
  $('#relocationOnboarding').addClass('item last');
  });
}

function LoadStartStep2NewGeoModalWeb() {

  $(document).ready(function () {
    $('#start_modal').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadStartStep1Modal1() {
  $(document).ready(function () {
    $('#start_modal1').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadPulseModalForWeb() {
  $(document).ready(function () {
    $('.pulse-modal').show();
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}
function LoadInductionFeedbackModalForWeb() {
  $(document).ready(function () {
    $('.inductionfeedback-modal').show();
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadAttendanceModalForWeb() {
  $(document).ready(function () {
    $('.attendance-modal').show();
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}
function LoadNotificationModalForWeb() {
  $(document).ready(function () {
    $('.notification-modal').show();
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadFeedbackModalForWeb() {
  $(document).ready(function () {
    $('.feedback-modal').show();
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}


function LoadStartStep1Modal2() {
  $(document).ready(function () {
    $('#start_modal2').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadStartStep1CloseModal() {
  $(document).ready(function () {
    $('#start_modal').modal('hide');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadOnBoardingPassModal() {
  $(document).ready(function () {
    $('#pass_modal').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadOnBoardingPassCloseModal() {
  $(document).ready(function () {
    $('#pass_modal').modal('hide');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function LoadRequestCallCloseModal() {
  $(document).ready(function () {
    $('#request_modal').modal('hide');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}
function LoadRequestCallCloseModalWeb() {
  $(document).ready(function () {
    $('.request-modal').fadeOut(); 
      
  });
}
function LoadPulseCloseModalWeb() {
  $(document).ready(function () {
    $('.pulse-modal').fadeOut(); 
      
  });
}
function LoadInductionFeedbackCloseModalWeb() {
  $(document).ready(function () {
    $('.inductionfeedback-modal').fadeOut(); 
      
  });
}
function LoadAttendanceCloseModalWeb() {
  $(document).ready(function () {
    $('.attendance-modal').fadeOut(); 
      
  });
}

function LoadNotificationCloseModalWeb() {
  $(document).ready(function () {
    $('.notification-modal').fadeOut(); 
      
  });
}
function LoadFeedbackCloseModalWeb() {
  $(document).ready(function () {
    $('.feedback-modal').fadeOut(); 
      
  });
}

function LoadFeedbackModalForWeb() {
  $(document).ready(function () {
  $('.feedback-modal').show();
  $('.modal-backdrop').addClass('fade');
  $('.modal-backdrop').css('opacity', '0.5');
  });
  } 

function LoadMailerCloseModalWeb(){
  $(document).ready(function () {
    $('.mailer-modal').fadeOut(); 
      
  });
}

function LoadStyleForIcons(i, c, a, s, b) {
  if (i == "Y") {
    $('#id').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#contact').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#address').closest('.owl-item').remove();
  }
  if (s == "Y") {
    $('#ss').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bnk').closest('.owl-item').remove();
  }
}
//Common Module icon pending mob
function LoadOwlCarouselForMenu_Glo_Mobile() {
  $(document).ready(function () {
    var carousel = $("#menu_carouselGlMob");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });



}
function LoadOwlCarouselForMenuOnAcknowledgementMain_Pending() {
  $(document).ready(function () {
    var carousel = $("#menu_carousel");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').addClass('completed');
  $('#bnk').addClass('completed');
  $('#contractDoc').addClass('completed');
  $('#kiwi').addClass('completed');
  $('#cfn').addClass('completed');
  $('#disability').addClass();
  $('#medical').addClass('completed');
  $('#po').removeClass();
  $('#po').focus();
  $('#po').addClass('selected');
  $('#po').addClass('item last');



  });
  
  } 
function ChangestartImageGlo_Mob() {
  $('#start').removeClass();
  $('#start').addClass('selected');
  $('#id').removeClass();
  $('#contact').removeClass();
  $('#pers').removeClass();
  $('#address').removeClass();
  $('#nric').removeClass();
  $('#fam').removeClass();
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeIdImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').removeClass();
  $('#id').addClass('selected');
  $('#contact').removeClass();
  $('#pers').removeClass();
  $('#address').removeClass();
  $('#nric').removeClass();
  $('#fam').removeClass();
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeContactImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').removeClass();
  $('#contact').addClass('selected');
  $('#pers').removeClass();
  $('#address').removeClass();
  $('#nric').removeClass();
  $('#fam').removeClass();
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').addClass('completed');
  $('#disability').removeClass();
  $('#natId').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangePersImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').removeClass();
  $('#pers').addClass('selected');
  $('#address').removeClass();
  $('#nric').removeClass();
  $('#fam').removeClass();
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').addClass('completed');
  $('#disability').removeClass();
  $('#natId').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangesAddImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').removeClass();
  $('#address').addClass('selected');
  $('#nric').removeClass();
  $('#fam').removeClass();
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').addClass('completed');
  $('#disability').removeClass();
  $('#natId').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeNricImageGlo_Mob() {;
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').removeClass();
  $('#nric').addClass('selected');
  $('#fam').removeClass();
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeFamImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').removeClass();
  $('#fam').addClass('selected');
  $('#pass').removeClass();
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').addClass('completed');
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangePassImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').removeClass();
  $('#pass').addClass('selected');
  $('#resiper').removeClass();
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeResiImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').removeClass();
  $('#resiper').addClass('selected');
  $('#hol').removeClass();
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeHolImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').removeClass();
  $('#hol').addClass('selected');
  $('#ss').removeClass();
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeSSNImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').removeClass();
  $('#ss').addClass('selected');
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').addClass('completed');
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}
function ChangeCFNImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').addClass('selected')
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}
function ChangedisabilityImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').addClass('selected')
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}
function ChangeNatIdImageGlo_Mob(){
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').remove();
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').removeClass();
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').addClass('selected');
  $('#natId').removeClass();
  $('#cfn').removeClass();
  $('#medical').addClass('completed');
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeTxfImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').removeClass();
  $('#txf').addClass('selected');
  $('#tx').removeClass();
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeTaxImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').removeClass();
  $('#tx').addClass('selected');
  $('#bnk').removeClass();
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeBankImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').addClass('completed');
  $('#bnk').removeClass();
  $('#bnk').addClass('selected');
  $('#contractDoc').removeClass();
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').addClass('completed');
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeContImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').addClass('completed');
  $('#bnk').addClass('completed');
  $('#contractDoc').removeClass();
  $('#contractDoc').addClass('selected');
  $('#kiwi').removeClass();
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}

function ChangeKiwiImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').addClass('completed');
  $('#bnk').addClass('completed');
  $('#contractDoc').addClass('completed');
  $('#kiwi').removeClass();
  $('#kiwi').addClass('selected');
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#medical').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}
function ChangeMedicalImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').addClass('completed');
  $('#bnk').addClass('completed');
  $('#contractDoc').addClass('completed');
  $('#medical').removeClass();
  $('#medical').addClass('selected');
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').removeClass();
  $('#po').removeClass();
  $('#po').addClass('item last');
}
function ChangePolicyImageGlo_Mob() {
  $('#start').addClass('completed');
  $('#id').addClass('completed');
  $('#contact').addClass('completed');
  $('#pers').addClass('completed');
  $('#address').addClass('completed');
  $('#nric').addClass('completed');
  $('#fam').addClass('completed');
  $('#pass').addClass('completed');
  $('#resiper').addClass('completed');
  $('#hol').addClass('completed');
  $('#ss').addClass('completed');
  $('#txf').addClass('completed');
  $('#tx').addClass('completed');
  $('#bnk').addClass('completed');
  $('#contractDoc').addClass('completed');
  $('#kiwi').addClass('completed');
  $('#cfn').removeClass();
  $('#disability').removeClass();
  $('#natId').addClass('completed');
  $('#medical').addClass('completed');
  $('#po').removeClass();
  $('#po').focus();
  $('#po').addClass('selected');
  $('#po').addClass('item last');
}



function LoadStyleForIconsGlobal(i, c, a, b, p, s, t, po, f, pp, cc, ki,resip,contdoc,taxF,nric,holiday,benefitMain,cfn,medical,natId,disability) {
  if (i == "Y") {
    $('#id').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#contact').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#address').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bnk').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#pers').closest('.owl-item').remove();
  }
  if (s == "Y") {
    $('#ss').closest('.owl-item').remove();
  }
  
  if (t == "Y") {
    $('#tx').closest('.owl-item').remove();
  }
  if (po == "Y") {
    $('#po').closest('.owl-item').remove();
  }

  if (f == "Y") {
    $('#fam').closest('.owl-item').remove();
  }

  if (pp == "Y") {
    $('#pass').closest('.owl-item').remove();
  }

  if (cc == "Y") {
    $('#corp').closest('.owl-item').remove();
  }

  if (cc == "Y") {
    $('#corp').closest('.owl-item').remove();
  }

  if (ki == "Y") {
    $('#kiwi').closest('.owl-item').remove();
  }

  if (resip == "Y") {
    $('#resiper').closest('.owl-item').remove();
  }

  if (contdoc == "Y") {
    $('#contractDoc').closest('.owl-item').remove();
  }

  if (taxF == "Y") {
    $('#txf').closest('.owl-item').remove();
  }

  if (nric == "Y") {
    $('#nric').closest('.owl-item').remove();
  }
  
  if(holiday == "Y"){
    $('#hol').closest('.owl-item').remove();
  }

  if(benefitMain == "Y"){
    $('#ben').closest('.owl-item').remove();
  }
  if(cfn == "Y"){
    $('#cfn').closest('.owl-item').remove();
  }
  if(disability == "Y"){
    $('#disability').closest('.owl-item').remove();
  }
  if (medical == "Y") {
    $('#medical').closest('.owl-item').remove();
  }
  if (natId == "Y") {
    $('#natId').closest('.owl-item').remove();
  }
}

function LoadStyleForVanguardIcons(i, c, a, s, b, w,p,t) {
  if (i == "Y") {
    $('#id').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#contact').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#address').closest('.owl-item').remove();
  }
  if (s == "Y") {
    $('#ss').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bnk').closest('.owl-item').remove();
  }
  if (w == "Y") {
    $('#w4').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#policy').closest('.owl-item').remove();
  }
  if (t == "Y") {   
     $('#tfn').closest('.owl-item').remove();  
    }
}

function LoadStyleForUSPendingIcons(i, c, a, s, b, w,p,t,d,v) {
  if (i == "Y") {
    $('#id').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#contact').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#address').closest('.owl-item').remove();
  }
  if (s == "Y") {
    $('#ss').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bnk').closest('.owl-item').remove();
  }
  if (w == "Y") {
    $('#w4').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#policy').closest('.owl-item').remove();
  }
  if (t == "Y") {   
     $('#tfn').closest('.owl-item').remove();  
    }
  if (d == "Y") {
    $('#disability').closest('.owl-item').remove();
  }
  if (v == "Y") {
    $('#veteran').closest('.owl-item').remove();
  }
}

function LoadStyleForIconsUSBPO(i, c, a, s, b,p,d,v) {
  if (i == "Y") {
    $('#id').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#contact').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#address').closest('.owl-item').remove();
  }
  if (s == "Y") {
    $('#ss').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bnk').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#policy').closest('.owl-item').remove();
  }
  if (d == "Y") {
    $('#disability').closest('.owl-item').remove();
  }
  if (v == "Y") {
    $('#veteran').closest('.owl-item').remove();
  }
}

function LoadStyleForVanguardIconsUSBPO(i, c, a, s, b,p,w,t,d,v) {
  if (i == "Y") {
    $('#id').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#contact').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#address').closest('.owl-item').remove();
  }
  if (s == "Y") {
    $('#ss').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bnk').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#policy').closest('.owl-item').remove();
  }
  if (w == "Y") {
    $('#w4bpo').closest('.owl-item').remove();
  }
  if (t == "Y") {    
    $('#tfn').closest('.owl-item').remove(); 
   }
   if (d == "Y") {
    $('#disability').closest('.owl-item').remove();
  }
  if (v == "Y") {
    $('#veteran').closest('.owl-item').remove();
  }
}
// function LoadStyleForIconsUK(i,c,a,p,b){
// 	if(i=="Y"){
// 		$('#badge').css('display','none');
// 	}
// 	if(c=="Y"){
// 		$('#emer').css('display','none');
// 	}
// 	if(a=="Y"){
// 		$('#add').css('display','none');
// 	}
// 	if(p=="Y"){
// 		$('#per').css('display','none');
// 	}
// 	if(b=="Y"){
// 		$('#bank').css('display','none');
// 	}
// 	}
function LoadStyleForIconsUK(i, c, a, p, b,policy,tax,insurance,resipermit,passport,joiningdocs) {
  if (i == "Y") {
    $('#badge').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#emer').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#add').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#per').closest('.owl-item').remove();
  }
  if (tax == "Y"){
    $('#taxuk').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bank').closest('.owl-item').remove();
  }
  if (policy == "Y") {
    $('#policyuk').closest('.owl-item').remove();
  }
  if(tax=="Y"){
    $('#taxuk').closest('.owl-item').remove();
  }
  if(insurance=="Y"){
    $('#insuranceuk').closest('.owl-item').remove();
  }
  if(resipermit =="Y"){
    $('#resipermituk').closest('.owl-item').remove();
  }
  if(passport =="Y"){
    $('#passportuk').closest('.owl-item').remove();
  }
  if(joiningdocs =="Y"){
    $('#joiningdocsuk').closest('.owl-item').remove();
  }
}

function LoadStyleForIconsUS(i, c, a, p, b,policy,tax,insurance) {
  if (i == "Y") {
    $('#badge').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#emer').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#add').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#per').closest('.owl-item').remove();
  }
  if (tax == "Y"){
    $('#taxus').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bank').closest('.owl-item').remove();
  }
  if (policy == "Y") {
    $('#policyus').closest('.owl-item').remove();
  }
  if(tax=="Y"){
    $('#taxus').closest('.owl-item').remove();
  }
  if(insurance=="Y"){
    $('#insuranceus').closest('.owl-item').remove();
  }
}

function changePayrollIcon() {
  $('#pay').removeClass();
  $('#pay').addClass('selected');

  $('#ins').removeClass();
  $('#policyus').removeClass();
  $('#disable').removeClass();
  $('#veteran').removeClass();
  $('#veteran').addClass('item last');

}

function changeBenefitsIcon() {
  $('#benefitMain').removeClass();
  $('#benefitMain').addClass('selected');
  $('#benefitMain').addClass('item last');
}

function ChangeFamImageIBPOCR_Mob() {
  $('#start').addClass('completed');
  $('#badge').addClass('completed');
  $('#ec').addClass('completed');
  $('#personal').addClass('completed');
  $('#residence').addClass('completed');
  $('#bank').addClass('completed');
  $('#nationalid').addClass('completed');
  $('#criminalrecord').addClass('completed');
  $('#policyuk').addClass('completed');
  $('#fam').removeClass();
  $('#fam').addClass('selected');
  $('#fam').addClass('item last');
}


function ChangeAddrIcompaz_Mob() {
  $('#start').addClass('completed');
 
  $('#personal').addClass('completed');
  $('#pass').addClass('completed');
  $('#mi').addClass('completed');
  $('#nric').addClass('completed');
  $('#policyuk').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#bank').addClass('item last');
}
function ChangeBankIcompaz_Mob() {
  $('#start').addClass('completed');
 
  $('#personal').addClass('completed');
  $('#pass').addClass('completed');
  $('#mi').addClass('completed');
  $('#nric').addClass('completed');
  $('#policyuk').addClass('completed');
  $('#residence').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#bank').addClass('item last');
}

function ChangeFamRemImageIBPOCR_Mob() {
  $('#fam').removeClass();
}

function ChangeAddrRemIcompaz_Mob() {
  $('#residence').removeClass();
}

function changeInsuranceIcon() {

  $('#pay').addClass('completed');

  $('#ins').removeClass();
  $('#ins').addClass('selected');
  $('#policyus').removeClass();
  $('#disabilityUS').removeClass();
  $('#veteranUS').removeClass();
  $('#veteran').addClass('item last');
  // $('#w').removeClass();
  // $('#w').addClass('item last');

}


function changeW4Icon() {

  $('#pay').addClass('completed');

  $('#ins').addClass('completed');
  $('#w').removeClass();
  $('#w').addClass('selected');
  $('#w').addClass('item last');

}
function ChangePolicyIcon() {

//alert('Changed icon called')
  $('#pay').addClass('completed');

  $('#ins').addClass('completed');
  $('#policyus').removeClass();
  $('#policyus').addClass('selected');
  $('#disabilityUS').removeClass();
  $('#veteranUS').removeClass();
  $('#policyus').addClass('item last');
 

}
function ChangeDisabilityImage(){
  $('#pay').addClass('completed');

  $('#ins').addClass('completed');
  $('#policyus').removeClass();
  $('#policyus').addClass('completed');
  $('#disabilityUS').removeClass();
  $('#disabilityUS').addClass('selected');
  $('#veteranUS').removeClass();
  $('#policyus').addClass('item last');
}
function ChangeVeteranImage(){
  $('#pay').addClass('completed');

  $('#ins').addClass('completed');
  $('#policyus').removeClass();
  $('#policyus').addClass('completed');
  $('#disabilityUS').removeClass();
  $('#disabilityUS').addClass('completed');
  $('#veteranUS').removeClass();
  $('#veteranUS').addClass('selected');
  $('#policyus').addClass('item last');
}

// function ChangeTravelImage() {
//   $('#travel').removeClass();
//   $('#travel').addClass('selected');
//   $('#accommodation').removeClass();
//   $('#shuttle').addClass('completed');
//   $('#food').removeClass();
//   $('#food').addClass('item last');
//   $('#salary').removeClass();
// }
function ChangeTravelImageLateral() {
  $('#travel').removeClass();
  $('#travel').addClass('selected');
  // $('#accommodation').add();
  $('#accommodation').show();
  $('#accommodation').removeClass();
  $('#food').show();
  $('#food').removeClass();
  $('#relocation').show();
  $('#relocation').removeClass();
  $('#relocation').addClass('item last');
}

function ChangeTravelImageLateralIBPOMccam() {
  $('#travel').removeClass();
  $('#travel').addClass('selected');
  // $('#accommodation').add();
  // $('#accommodation').show();
  // $('#accommodation').removeClass();
  // $('#food').show();
  // $('#food').removeClass();
  $('#relocation').show();
  $('#relocation').removeClass();
  $('#relocation').addClass('item last');
}

function ChangeAccommodationImageLateral() {
  $('#travel').removeClass();
  $('#travel').addClass('completed');
  $('#accommodation').removeClass();
  $('#accommodation').addClass('selected');
  $('#food').removeClass();
  $('#relocation').removeClass();
  $('#relocation').addClass('item last');
}

function ChangeFoodImageLateral() {
  $('#travel').removeClass();
  $('#travel').addClass('completed');
  $('#accommodation').removeClass();
  $('#accommodation').addClass('completed');
  $('#food').removeClass();
  $('#food').addClass('selected');
  $('#relocation').removeClass();
  $('#relocation').addClass('item last');
}

function ChangeRelocationImageLateral() {
  $('#travel').removeClass();
  $('#travel').addClass('completed');
  $('#accommodation').removeClass();
  $('#accommodation').addClass('completed');
  $('#food').removeClass();
  $('#food').addClass('completed');
  $('#relocation').removeClass();
  $('#relocation').addClass('selected');
  $('#relocation').addClass('item last');
}

function ChangeRelocationImageLateralIBPOMccam() {
  $('#travel').removeClass();
  $('#travel').addClass('completed');
  // $('#accommodation').removeClass();
  // $('#accommodation').addClass('completed');
  // $('#food').removeClass();
  // $('#food').addClass('completed');
  $('#relocation').removeClass();
  $('#relocation').addClass('selected');
  $('#relocation').addClass('item last');
}

function Step2StartLateral() {
  $('#travel').removeClass();
  $('#accommodation').removeClass();
  $('#food').removeClass();
  $('#relocation').removeClass();
  $('#relocation').addClass('item last');
}

function Step2TravelLateral() {
  $('#accommodation').hide();
  $('#food').hide();
  $('#relocation').hide();
  $('#travel').removeClass();
  $('#travel').addClass('selected');
  // $('#travel').remove('after');
  $('#travel').addClass('item last');
 
}
function Step2TravelLateral1() {
  $('#accommodation').hide();
  $('#food').hide();
  $('#relocation').hide();
  $('#travel').removeClass();
  // $('#travel').addClass('selected');
  // $('#travel').remove('after');
  $('#travel').addClass('item last');
 
}

function Step2Start() {
  $('#travel').removeClass();
  $('#accommodation').removeClass();
  $('#shuttle').removeClass();
  $('#food').removeClass();
  $('#food').addClass('item last');
  $('#salary').removeClass();
}

function ChangeTravelImage() {
  $('#travel').removeClass();
  $('#travel').addClass('selected');
  $('#accommodation').removeClass();
  $('#shuttle').removeClass();
  // $('#shuttle').addClass('completed');
  $('#food').removeClass();
  $('#food').addClass('item last');
  $('#salary').removeClass();
}

function ChangeAccImage() {
  $('#travel').addClass('completed');
  $('#accommodation').removeClass();
  $('#accommodation').addClass('selected');
  $('#shuttle').removeClass();
  $('#food').removeClass();
  $('#food').addClass('item last');
  $('#salary').removeClass();
}

// function ChangeShuttleImage() {
//   $('#travel').removeClass();
//   $('#accommodation').removeClass();
//   $('#shuttle').removeClass();
//   $('#shuttle').addClass('selected');
//   $('#food').removeClass();
//   $('#food').addClass('item last');
//   $('#salary').removeClass();
// }

function ChangeShuttleImage() {
  $('#travel').removeClass();
  $('#travel').addClass('completed');
  $('#accommodation').removeClass();
  $('#accommodation').addClass('completed');
  $('#shuttle').removeClass();
  $('#shuttle').addClass('selected');
  $('#food').removeClass();
  $('#food').addClass('item last');
  $('#salary').removeClass();
}

function ChangeFoodImage() {
  $('#travel').addClass('completed');
  $('#accommodation').addClass('completed');
  $('#shuttle').addClass('completed');
  $('#food').removeClass();
  $('#food').addClass('selected');
  $('#food').addClass('item last');
  $('#salary').addClass('completed');
}

function ChangeSalaryImage() {
  $('#travel').addClass('completed');
  $('#accommodation').addClass('completed');
  $('#shuttle').addClass('completed');
  $('#food').removeClass();
  $('#food').addClass('item last');
  $('#salary').removeClass();
  $('#salary').addClass('selected');

}

function NewGeosTravelStartingUnselected() {
  $('#travel').removeClass();
  $('#travel').removeClass();
  $('#travel').addClass('item last');
  $('#relocation').hide();
  $('#relocationOnboarding').hide();
}

function NewGeosTravelStarting() {
  $('#travel').show();
  $('#travel').addClass('selected');
  $('#travel').addClass('item last');
  $('#relocation').hide();
  $('#relocationOnboarding').hide();
}

function NewGeosTravelSelected() {
  $('#travel').show();
  $('#relocation').show();
  $('#relocationOnboarding').show();
  $('#travel').removeClass();
  $('#travel').addClass('selected');
  $('#relocation').removeClass();
  $('#relocationOnboarding').removeClass();
  $('#relocationOnboarding').addClass('item last');
}

function NewGeosRelocationSelected() {
  $('#travel').show();
  $('#relocation').show();
  $('#relocationOnboarding').show();
  $('#travel').removeClass();
  $('#relocation').removeClass();
  $('#relocation').addClass('selected');
  $('#relocationOnboarding').removeClass();
  $('#relocationOnboarding').addClass('item last');
}

function NewGeosRelocationOnboardingSelected() {
  $('#travel').show();
  $('#relocation').show();
  $('#relocationOnboarding').show();
  $('#travel').removeClass();
  $('#relocation').removeClass();
  $('#relocationOnboarding').removeClass();
  $('#relocationOnboarding').addClass('selected');
  $('#relocationOnboarding').addClass('item last');
}

//AU Icons
function ChangeStartScreenImageAU() {
  $('#start').removeClass();
  $('#start').addClass('item active');
  $('#emer').removeClass();
  $('#badge').removeClass();
  $('#tax').removeClass();
  $('#add').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
}

function ChangeIdBadgeImageAU() {
  $('#start').addClass('completed');
  $('#emer').removeClass();
  $('#emer').addClass('selected');
  $('#badge').addClass('completed');
  $('#tax').removeClass();
  $('#add').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
}

function ChangeEmergencyImageAU() {
  $('#start').addClass('completed');
  $('#emer').removeClass();
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#tax').removeClass();
  $('#add').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
}

function ChangeAddressImageAU() {
  //alert("Hi");
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').removeClass();
  $('#add').addClass('selected');
  $('#tax').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
}

function ChangePersonalImageAU() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').removeClass();
  $('#tax').addClass('selected');
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
 
}

function Changei9ImageAU() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').removeClass();
  $('#super').addClass('selected');
  $('#ssn').removeClass();
  $('#bank').removeClass();
  $('#policy').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [4, 1]);
  
}
function ChangedisImageAU() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').removeClass();
  $('#super').addClass('selected');
  $('#ssn').removeClass();
  $('#bank').removeClass();
  $('#policy').addClass('item last');
  $("#menu_carousel").trigger("to.owl.carousel", [4, 1]);
  
}

function ChangeBankImageAU() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#policy').removeClass();
  $('#policy').addClass('item last');
  
}

function ChangeSsnImageAU() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#tax').addClass('completed');
  $('#add').addClass('completed');
  $('#super').addClass('completed');
  $('#bank').addClass('completed');
  $('#policy').removeClass();
  $('#policy').addClass('selected');
  $('#policy').addClass('item last');
  
}

//AU pending Icons

function LoadOwlCarouselForMenu_AU_Mobile() {
  $(document).ready(function () {
    var carousel = $("#menu_carouselAUMob");
    carousel.owlCarousel({
      loop: false,
      margin: 16,
      center: false,
      dots: false,
      autoWidth: true,
      nav: false,
      startPosition: 0,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 3
        }
      }
    });
  });



}

function ChangeStartScreenImageAU_Moblie() {
  $('#start').removeClass();
  $('#start').addClass('item active');
  $('#emer').removeClass();
  $('#badge').removeClass();
  $('#tax').removeClass();
  $('#add').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
}

function ChangeIdBadgeImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').removeClass();
  $('#emer').addClass('selected');
  $('#badge').addClass('completed');
  $('#tax').removeClass();
  $('#add').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
}

function ChangeEmergencyImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').removeClass();
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#tax').removeClass();
  $('#add').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
}

function ChangeAddressImageAU_Moblie() {
  //alert("Hi");
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').removeClass();
  $('#add').addClass('selected');
  $('#tax').removeClass();
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [2, 1]);
}

function ChangePersonalImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').removeClass();
  $('#tax').addClass('selected');
  $('#super').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
 
}

function Changei9ImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').removeClass();
  $('#super').addClass('selected');
  $('#ssn').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#policy').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [4, 1]);
  
}
function ChangedisImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').removeClass();
  $('#super').addClass('selected');
  $('#ssn').removeClass();
  $('#dis').removeClass();
  $('#bank').removeClass();
  $('#policy').addClass('item last');
  // $("#menu_carousel").trigger("to.owl.carousel", [4, 1]);
  
}

function ChangeBankImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#dis').removeClass();
  $('#policy').removeClass();
  $('#policy').addClass('item last');
  
}
function ChangedisImageAU_Mobile()
{
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#add').addClass('completed');
  $('#tax').addClass('completed');
  $('#super').addClass('completed');
  $('#dis').removeClass();
  $('#dis').addClass('selected');
  $('#policy').removeClass();
  $('#policy').addClass('item last');
  
}
function ChangeSsnImageAU_Moblie() {
  $('#start').addClass('completed');
  $('#emer').addClass('completed');
  $('#badge').addClass('completed');
  $('#tax').addClass('completed');
  $('#add').addClass('completed');
  $('#super').addClass('completed');
  $('#bank').addClass('completed');
  $('#dis').addClass('completed');
  $('#policy').removeClass();
  $('#policy').addClass('selected');
  $('#policy').addClass('item last');
  
}

//AU Icons Post Joining
// function LoadStyleForIconsAU(id,em,tx,add,bnk,spn,pol){
// 	if(id=="Y"){
// 		$('#badge').css('display','none');
// 	}
// 	if(em=="Y"){
// 		$('#emer').css('display','none');
// 	}
// 	if(tx=="Y"){
// 		$('#tax').css('display','none');
// 	}
// 	if(add=="Y"){
// 		$('#add').css('display','none');
// 	}
// 	if(bnk=="Y"){
// 		$('#bank').css('display','none');
// 	}
// 	if(spn=="Y"){
// 		$('#super').css('display','none');
// 	}
// 	if(pol=="Y"){
// 		$('#policy').css('display','none');
// 	}
// 	}

function LoadStyleForIconsAU(id, em, tx, add, bnk, spn, pol,dis) {
  if (id == "Y") {
    $('#badge').closest('.owl-item').remove();
  }
  if (em == "Y") {
    $('#emer').closest('.owl-item').remove();
  }
  if (tx == "Y") {
    $('#tax').closest('.owl-item').remove();
  }
  if (add == "Y") {
    $('#add').closest('.owl-item').remove();
  }
  if (bnk == "Y") {
    $('#bank').closest('.owl-item').remove();
  }
  if (dis == "Y") {
    $('#dis').closest('.owl-item').remove();
  }
  if (spn == "Y") {
    $('#super').closest('.owl-item').remove();
  }
  if (pol == "Y") {
    $('#policy').closest('.owl-item').remove();
  }
}

function LoadJsForModalBackDrop() {
  $(document).ready(function () {
    // console.log("backdrop called");
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

//Icon change classes for NZ
function ChangeIdBadgeImageNZ() {
  $('#ec').removeClass();
  $('#ec').addClass('selected');
  $('#badge').addClass('completed');
  $('#personal').removeClass();
  $('#residence').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#ccard').removeClass();
  $('#ccard').addClass('item last');
}

function ChangeEmergencyImageNZ() {
  $('#ec').removeClass();
  $('#badge').removeClass();
  $('#badge').addClass('selected');
  $('#personal').removeClass();
  $('#residence').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#ccard').removeClass();
  $('#ccard').addClass('item last');
}

function ChangeAddressImageNZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').removeClass();
  $('#residence').addClass('selected');
  $('#personal').removeClass();
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#ccard').removeClass();
  $('#ccard').addClass('item last');
}

function ChangePersonalImageNZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').removeClass();
  $('#personal').addClass('selected');
  $('#bank').removeClass();
  $('#dis').removeClass();
  $('#ccard').removeClass();
  $('#ccard').addClass('item last');
}

function ChangeBankImageNZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#bank').removeClass();
  $('#bank').addClass('selected');
  $('#dis').removeClass();
  $('#ccard').removeClass();
  $('#ccard').addClass('item last');
}

function ChangeCorpCardImageNZ() {
  $('#ec').addClass('completed');
  $('#badge').addClass('completed');
  $('#residence').addClass('completed');
  $('#personal').addClass('completed');
  $('#bank').addClass('completed');
  $('#dis').addClass('completed');
  $('#ccard').removeClass();
  $('#ccard').addClass('item last');
}

function LoadStyleForIconsNZ(i, c, a, p, b) {
  if (i == "Y") {
    $('#badge').closest('.owl-item').remove();
  }
  if (c == "Y") {
    $('#emer').closest('.owl-item').remove();
  }
  if (a == "Y") {
    $('#add').closest('.owl-item').remove();
  }
  if (p == "Y") {
    $('#per').closest('.owl-item').remove();
  }
  if (b == "Y") {
    $('#bank').closest('.owl-item').remove();
  }

  
}

function ScrollToTop(){
  $("html, body").animate({ scrollTop: 0 }, "slow");
}



$('#actions_carousel, #offer_carousel').owlCarousel({
  loop: false,
  margin: 16,
  center: false,
  dots: false,
  autoWidth: true,
  nav:false,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive : {
    0 : {
       items: 3
    },
    768 : {
        items: 3
    }
  }
});

function LoadOwlCarouselForGallery(){
  $(document).ready(function () {
$('.gallery_carousel').owlCarousel({
  margin: 16,
  nav: false,
  loop: false,
  dots: false,
  autoWidth: true,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive : {
    0 : {
       items: 1
    },
    768 : {
        items: 3
        
    }
  }
});
  });
}

$('.customModal').on('shown.bs.modal', function (e) {
  var modal_height = $(this).height();
  var header_h = $(this).find('.modal-header').outerHeight();		
  var scroll_h = modal_height - header_h;
  $(this).find('.scrollbar-outer').css('height', scroll_h);
  //$(this).find('.scrollbar-outer').scrollbar();
});


$('.customModal1').on('shown.bs.modal', function (e) {
  var modal_height = $(this).height();
  var header_h = $(this).find('.modal-header').outerHeight();		
  var scroll_h = modal_height - header_h;
  $(this).find('.scrollbar-outer').css('height', scroll_h);
  //$(this).find('.scrollbar-outer').scrollbar();
});
function MakeClubActive(id){
  $(document).ready(function () {
    //alert(id);
    $('#club'+id).prop('checked',true);
  });
 
 
}

function SwipeToRight(){
  $('#firstDotImage').addClass('active');
  $('#id-front').addClass('active');
  $('#id-back').removeClass('active');
  $('#id-front1').addClass('active');
  $('#id-back1').removeClass('active');
  $('#secondDotImage').removeClass('active');
}

function SwipeToLeft(){
  $('#firstDotImage').removeClass('active');
  $('#id-front').removeClass('active');
  $('#id-back').addClass('active');
  $('#id-front1').removeClass('active');
  $('#id-back1').addClass('active');
  $('#secondDotImage').addClass('active');
}

/*Added By Dravita For Web*/
function LoadOwlCarouselForGalleryForWeb(){
  $(document).ready(function () {
$('#prev_next_carousel').owlCarousel({
  margin: 16,
  nav: true,
  loop: false,
  dots: true,
  autoWidth: true,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive : {
    0 : {
       items: 1
    },
    768 : {
        items: 3
        
    },
    1024:{
      items:3
    }
  }
});
  });
}
/*changes End*/

/*Added by Divyanshu*/
function LoadOwlCarouselForStaticWeb(){
  $(document).ready(function () {
$('#prev_next_carousel1').owlCarousel({
  margin: 16,
  nav: true,
  loop: false,
  dots: true,
  autoWidth: true,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive : {
    0 : {
       items: 1
    },
    768 : {
        items: 3
        
    },
    1024:{
      items:3
    }
  }
});
  });
}

function LoadOwlCarouselForStaticWeb1(){
  $(document).ready(function () {
$('#prev_next_carousel2').owlCarousel({
  margin: 16,
  nav: true,
  loop: false,
  dots: true,
  autoWidth: true,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive : {
    0 : {
       items: 1
    },
    768 : {
        items: 3
        
    },
    1024:{
      items:3
    }
  }
});
  });
}

function LoadOwlCarouselForStaticWeb2(){
  $(document).ready(function () {
$('#prev_next_carousel3').owlCarousel({
  margin: 16,
  nav: true,
  loop: false,
  dots: true,
  autoWidth: true,
  responsiveClass: true,
  responsiveRefreshRate: true,
  responsive : {
    0 : {
       items: 1
    },
    768 : {
        items: 3
        
    },
    1024:{
      items:3
    }
  }
});
  });
}
/*End*/

$('.menu_ul li a.icon_smiley1').click(function(){
  //alert('selected');
});

function ChangeStar1(id1) {
  $("#star1"+id1).addClass('selected');
  $('#star2' +id1).removeClass();
  $('#star3' +id1).removeClass();
  $('#star4' +id1 ).removeClass();
  $('#star5' +id1).removeClass();

}
function ChangeStar2(id1) {
  $('#star1'+id1).addClass('selected');
  $('#star2'+id1).addClass('selected');
  $('#star3'+id1).removeClass();
  $('#star4'+id1).removeClass();
  $('#star5'+id1).removeClass();

}
function ChangeStar3(id1) {
  $('#star1'+id1).addClass('selected');
  $('#star2'+id1).addClass('selected');
  $('#star3'+id1).addClass('selected');
  $('#star4'+id1).removeClass();
  $('#star5'+id1).removeClass();

}
function ChangeStar4(id1) {
  $('#star1'+id1).addClass('selected');
  $('#star2'+id1).addClass('selected');
  $('#star3'+id1).addClass('selected');
  $('#star4'+id1).addClass('selected');
  $('#star5'+id1).removeClass();

}
function ChangeStar5(id1) {
  $('#star1'+id1).addClass('selected');
  $('#star2'+id1).addClass('selected');
  $('#star3'+id1).addClass('selected');
  $('#star4'+id1).addClass('selected');
  $('#star5'+id1).addClass('selected');

}


function ChangeSmiley11(id1) {
  $("#star1"+id1).addClass('selected');
  $('#star2' +id1).removeClass();
  $('#star3' +id1).removeClass();
  $('#star4' +id1 ).removeClass();
  $('#star5' +id1).removeClass();

}
function ChangeSmiley22(id1) {
  $('#star1'+id1).removeClass();
  $('#star2'+id1).addClass('selected');
  $('#star3'+id1).removeClass();
  $('#star4'+id1).removeClass();
  $('#star5'+id1).removeClass();

}
function ChangeSmiley33(id1) {
  $('#star1'+id1).removeClass();
  $('#star2'+id1).removeClass();
  $('#star3'+id1).addClass('selected');
  $('#star4'+id1).removeClass();
  $('#star5'+id1).removeClass();

}
function ChangeSmiley44(id1) {
  $('#star1'+id1).removeClass();
  $('#star2'+id1).removeClass();
  $('#star3'+id1).removeClass();
  $('#star4'+id1).addClass('selected');
  $('#star5'+id1).removeClass();

}
function ChangeSmiley55(id1) {
  $('#star1'+id1).removeClass();
  $('#star2'+id1).removeClass();
  $('#star3'+id1).removeClass();
  $('#star4'+id1).removeClass();
  $('#star5'+id1).addClass('selected');
}




function ChangeSmiley1(id1,id2,id3,id4,id5) {
  $('#'+id1).addClass('selected');
  $('#'+id2).removeClass();
  $('#'+id3).removeClass();
  $('#'+id4).removeClass();
  $('#'+id5).removeClass();
  $('#'+id5).parent().parent().parent().siblings('.enterReason').hide();
}

function ChangeSmiley2(id1,id2,id3,id4,id5) {
  $('#'+id1).removeClass();
  $('#'+id2).addClass('selected');
  $('#'+id3).removeClass();
  $('#'+id4).removeClass();
  $('#'+id5).removeClass();
  $('#'+id5).parent().parent().parent().siblings('.enterReason').hide();
  // $('#A21').removeClass();
  // $('#A22').addClass('selected');
  // $('#A23').removeClass();
  // $('#A24').removeClass();
  // $('#A25').removeClass();
}

function ChangeSmiley3(id1,id2,id3,id4,id5) {
  $('#'+id1).removeClass();
  $('#'+id2).removeClass();
  $('#'+id3).addClass('selected');
  $('#'+id4).removeClass();
  $('#'+id5).removeClass();
  $('#'+id5).parent().parent().parent().siblings('.enterReason').hide();
}

function ChangeSmiley4(id1,id2,id3,id4,id5) {
  $('#'+id1).removeClass();
  $('#'+id2).removeClass();
  $('#'+id3).removeClass();
  $('#'+id4).addClass('selected');
  $('#'+id5).removeClass();
  $('#'+id5).parent().parent().parent().siblings('.enterReason').hide();
}

function ChangeSmiley4(id1,id2,id3,id4,id5) {
  $('#'+id1).removeClass();
  $('#'+id2).removeClass();
  $('#'+id3).removeClass();
  $('#'+id4).addClass('selected');
  $('#'+id5).removeClass();
  $('#'+id5).parent().parent().parent().siblings('.enterReason').hide();
}
function ChangeSmiley5(id1,id2,id3,id4,id5) {
  $('#'+id1).removeClass();
  $('#'+id2).removeClass();
  $('#'+id3).removeClass();
  $('#'+id4).removeClass();
  $('#'+id5).addClass('selected');
  $('#'+id5).parent().parent().parent().siblings('.enterReason').show();
}
function modalclose(){
  $(document).ready(function () {
    $('.message-modal').fadeOut();
    $('.headerInner_bg').fadeIn();
  });
}
function modalcloseWeb(){
  $(document).ready(function () {
    $('.message-modal').fadeOut();
  });
}
function modalOpenWeb(){
  $(document).ready(function () {
    $('.onboarding-modal').fadeIn();
    //$(".onboarding-modal").fadeOut();
    //$(".onboarding-modal").close();
  });
}
function RemoveDefaultBadgeImage() {
  $('#photoPreview').removeClass();
  //console.log("script")
  $('#photo').removeClass();

}
function AddDefaultBadgeImage() {
  $('#photoPreview').addClass("photo-prev default");
  $('#photo').addClass("id-preview default1");

}

// Added by dravita to make body scoll disable for modal open
function RemoveHeaderFixedAndBodyScroll(){
  window.scroll(0,0);
  $("header").removeClass("fixed");
  $("body").addClass("modal-open");
}

function AddHeaderFixedAndBodyScroll(){
  $("header").addClass("fixed");
  $("body").removeClass("modal-open");
}
function RemoveBodyScroll_Stay(){
  window.scroll(0,0);
  $("body").addClass("modal-open");
}
function AddBodyScroll_Stay(){
  $("body").removeClass("modal-open");
}


function HeaderFixed(){
  $("header").addClass("fixed");
}

function RemoveBodyscroll(){
  $("body").addClass("modal-open");
}

function AddBodyscroll(){
  $("body").removeClass("modal-open");
}
function AddClassHeaderFixed(){
  $("header").toggleClass("fixed");
}
function RemoveClassHeaderFixed(){
  $("header").removeClass("fixed");
}

//changes end 
function CloseModal(){
  //console.log("console")
  $('#call_modal').css("display","none");
  $('#call_modal').fadeOut();
  $('.modal').fadeOut();
  $('.modal-backdrop').fadeOut();

}


function onStartingIcici() {
  $('#iciciLabel').addClass('b-cards1');
  $('#iciciLabel').removeClass('b-cards2');
  //$('#axisLabel').addClass('b-cards2');
  }

function axisClickIfExists() {
  $('#iciciLabel').removeClass('b-cards1');
  $('#iciciLabel').addClass('b-cards2');
  $('#axisLabel').removeClass('b-cards2')
  $('#axisLabel').addClass('b-cards1');
  $('#existing').removeClass('selectbutton1');
  $('#existing').removeClass('selectbutton2');
  $('#existing').addClass('selectbutton1');
  }

  function axisClickIfNotExists() {
    $('#iciciLabel').removeClass('b-cards1');
    $('#iciciLabel').addClass('b-cards2');
    $('#axisLabel').addClass('b-cards1');
    $('#existing').removeClass('selectbutton2');
    $('#existing').removeClass('selectbutton1');
    $('#existing').addClass('selectbutton1');
    }

  function iciciClickIfExists() {
    $('#axisLabel').removeClass('b-cards1');
    $('#axisLabel').addClass('b-cards2');
    $('#iciciLabel').addClass('b-cards1');
    $('#existing').removeClass('selectbutton1');
    $('#existing').removeClass('selectbutton2');
    $('#existing').addClass('selectbutton1');
    }

    function iciciClickIfNotExists() {
      $('#axisLabel').removeClass('b-cards1');
      $('#axisLabel').addClass('b-cards2');
      $('#iciciLabel').addClass('b-cards1');
      $('#existing').removeClass('selectbutton2');
      $('#existing').removeClass('selectbutton1');
      $('#existing').addClass('selectbutton1');
      }
  
  function onStartingAxis() {
    $('#axisLabel').addClass('b-cards1');
    $('#iciciLabel').addClass('b-cards2');
    }


    function onStartingExistIcici() {
      //$('#new').addClass('selectbutton');
      //console.log("checking JS")
      $("#new").removeAttr("class");
      $('#new').addClass('selectbutton2');
      $('#existing').addClass('selectbutton1');
     }
     function onStartingExistAxis() {
      $("#new").removeAttr("class");
      $('#new').addClass('selectbutton2');
      $('#existing').addClass('selectbutton1');
     }
      function onStarting() {
        $("#new").removeAttr("class");
        $('#existing').addClass('selectbutton2');
        $('#new').addClass('selectbutton2');
        }
    function onClickNew() {
      $("#new").removeAttr("class");
      $('#new').removeClass('selectbutton2');
      $('#new').addClass('selectbutton1');
      $('#existing').removeClass('selectbutton1');
      $('#existing').addClass('selectbutton2');
      }
    
      function onClickExisting() {
        $("#new").removeAttr("class");
        $('#existing').removeClass('selectbutton2');
        $('#existing').addClass('selectbutton1');
        $('#new').removeClass('selectbutton1');
        $('#new').addClass('selectbutton2');
        }


        function onStartingResNull() {
          // $("#new").removeAttr("class");
          // $('#existing').removeClass('selectbutton2');
          $('#existing').addClass('selectbutton1');
          $('#iciciLabel').addClass('b-cards1');
          // $('#new').removeClass('selectbutton1');
          // $('#new').addClass('selectbutton2');
          }

          function removeForm(){
            //$(this).parent().siblings('.otp-form').hide();
            $(".otp-form").hide();
          }
        // function onClickIciciNew() {
        //   $("#new").removeAttr("class");
        //   $('#existing').removeClass('selectbutton1');
        //   $('#existing').addClass('selectbutton2');
        //   $('#new').removeClass('selectbutton2');
        //   $('#new').addClass('selectbutton1');
        //   }
// function iciciClick() {
//   $('#iciciLabel').removeClass('b-cards');
//   $('#iciciLabel').addClass('b-cards');
//   }    

function IdbadgeIssueModalClose(){
  //console.log("console")
  // $('#idIssue').css("display","none");
  // $('#idIssue').fadeOut();
  // $('.modal').fadeOut();
  $('#idIssue').modal('hide');

}
function StartModalClose(){
  $('#start_modal').modal('hide'); 

}
function StartModalClose1(){
  $('#start_modal1').modal('hide'); 

}
function StartModalClose2(){
  $('#start_modal2').modal('hide'); 

}
function ClearFields(id) {
  document.getElementById(id).value = "";
}

$(document).on('click','.eduRead',function(){
  //alert('selected');
  $(this).siblings().show();
  $(this).hide();
  //parent.find('.eduWrite').addClass('selected');
});

// $(document).on('focus','input',function () {
//   $('html, body').animate({
//       scrollTop: (($(this).offset().top)-200) + 'px'
//   }, 'fast')});

// $(document).on('focus','input',function () {
//   $('html, body').animate({
//       scrollTop: (($(this).offset().top)-200) + 'px'
//   }, 'fast')});

function addCssForQrCode() {
  $(document).ready(function(){
    $('.code').children().find('img').css("height","80px")
    // $('.qrcode').css("height","80px")
  })
}

function addCssForMatElement() {
  $(document).ready(function(){
    $('input').find('.mat-input-element').css("font-weight","500")
  })
}

function removeScrolling() {
  $(document).ready(function(){
    $('#menu_carousel').addClass('dummy-cards')
    
  })
}

function getElement(id){
  // $(document).ready(function(){
  //   if(id==='bnk'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 13);
  //   }    
  //   else if(id==='id'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 14);
  //   }
    
  //   else if(id==='fam'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 0);
  //   }
  
  //   else if(id==='resi'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 12);

  //   }
  //   else if(id==='edu'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 3);

  //   }
  //   else if(id==='wrkex'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 4);

  //   }    
  //   else if(id==='pass'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 5);

  //   }
  //   else if(id==='gap'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 5);

  //   }
  //   else if(id==='dis'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 1);

  //   }
  //   else if(id==='nsr'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 9);
  //   }
  
  //   else if(id==='pan'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 8);
  //   }
  //   else if(id==='adhar'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 10);
  //   }
  //   else if(id==='acco'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 9);
  //   }    
  //   else if(id==='medi'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 11);
  //   }
  //   else if(id==='achieve'){
  //     $('#menu_carousel').trigger('to.owl.carousel', 10);
  //   }
  // })  
}

function OwlStageWidth() {
  $(document).ready(function(){
    $('.owl-carousel').children().find('.owl-stage').css("width","376px")
    // $('.qrcode').css("height","80px")
  })
}

function snackHeight(){
  $(document).ready(function(){
  $(".mat-snack-bar-container").css({"position": "absolute", "bottom": "60px","left":"-8px"});
 })
 }
function changeAccStepTwoIndia() {
  $('#accommodation').addClass('item last');
}

function RemoveJsForModalBackDrop() {
  $(document).ready(function () {
    // console.log("backdrop called");
    $('.modal-backdrop').removeClass('fade');
  });
}

function LoadDataSubmitModal() {
  $(document).ready(function () {
    $('#data_modal').modal('show');
    $('.modal-backdrop').addClass('fade');
    $('.modal-backdrop').css('opacity', '0.5');
  });
}

function CloseDataSubmitModal(){
  $('#data_modal').modal('hide'); 

}

function addClassActive(id1,id2){
 
}


function MakePersonalActive() {
  $(document).ready(function(){
    $('.ob-step-wizard').children().find('.personal-info').addClass('selected')
    // $('.qrcode').css("height","80px")
  })
}

function matFormBorderTopZero() {
  $(document).ready(function(){
    $('.matForm').children().find('mat-form-field-infix').css("border-top","0px")
    // $('.qrcode').css("height","80px")
  })
}
$('body').click(function (event) 
{
   if(!$(event.target).is('.option-modal')) {
     $(".option-modal").hide();
   }     
});
function matExpansionHeaderColorChange() {
  $(document).ready(function(){
    $('.mat-expansion-panel').children().find('.mat-expansion-panel-header').css("background","red")
    // $('.qrcode').css("height","80px")
  })
}
function makeBackActive() {  
  $(document).ready(function(){
    $('.id-front1').removeClass('active');
    $('.id-back1').addClass('active');
})};

function makeFrontActive() {  
  $(document).ready(function(){
    $('.id-back1').removeClass('active');
    $('.id-front1').addClass('active');
})};

function LoadMailerCloseModalWeb(){
  $(document).ready(function () {
  $('.mailer-modal').fadeOut(); 
  });
}

function loadScrollingJsForWeb(id){
  $(document).ready(function() {
    var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    });
    
    if(id==='fam'){
      owl.trigger('to.owl.carousel',0)      
    }
    else if(id==='edu'){
      owl.trigger('to.owl.carousel',0)
    }    
    else if(id==='dis'){
      owl.trigger('to.owl.carousel',0)
    }
    else if(id==='achieve'){
      owl.trigger('to.owl.carousel',1)      
    }
    else if(id==='aspi'){
      owl.trigger('to.owl.carousel',2) 
    } 
    else if(id==='wrkex'){
      owl.trigger('to.owl.carousel',3)
    } 
    else if(id==='gap'){
      owl.trigger('to.owl.carousel',4)
    }
    else if(id==='nsr'){
      owl.trigger('to.owl.carousel',5)      
    }
    else if(id==='pass'){
      owl.trigger('to.owl.carousel',6)  
    }
    else if(id==='pan'){
      owl.trigger('to.owl.carousel',7)      
    }
    else if(id==='adhar'){
      owl.trigger('to.owl.carousel',8)      
    }
    else if(id==='resi'){
      owl.trigger('to.owl.carousel',9)
    } 
    if(id==='bnk'){
      owl.trigger('to.owl.carousel',10)      
    }
    else if(id==='nomi'){
      owl.trigger('to.owl.carousel',11)     
    }
    else if(id==='medi'){
      owl.trigger('to.owl.carousel',12) 
    }
    else if(id==='id'){
      owl.trigger('to.owl.carousel',13)      
    }
    else if(id==='nats'){
      owl.trigger('to.owl.carousel',14)      
    }
    else {
      owl.trigger('to.owl.carousel',0)   
    }         
    
    // $('.play').on('click',function(){
    //     owl.trigger('play.owl.autoplay',10)
    // })   
  })
}

function loadScrollingJsForWeb(id){
  $(document).ready(function() {
    var owl = $('.owl-carousel');
    owl.owlCarousel({
        items:6,
        loop:true,
        margin:10,
    });
    
    if(id==='fam'){
      owl.trigger('to.owl.carousel',0)      
    }
    else if(id==='edu'){
      owl.trigger('to.owl.carousel',0)
    }    
    else if(id==='dis'){
      owl.trigger('to.owl.carousel',0)
    }
    else if(id==='achieve'){
      owl.trigger('to.owl.carousel',1)      
    }
    else if(id==='aspi'){
      owl.trigger('to.owl.carousel',2) 
    } 
    else if(id==='wrkex'){
      owl.trigger('to.owl.carousel',3)
    } 
    else if(id==='gap'){
      owl.trigger('to.owl.carousel',4)
    }
    else if(id==='nsr'){
      owl.trigger('to.owl.carousel',5)      
    }
    else if(id==='pass'){
      owl.trigger('to.owl.carousel',6)  
    }
    else if(id==='pan'){
      owl.trigger('to.owl.carousel',7)      
    }
    else if(id==='adhar'){
      owl.trigger('to.owl.carousel',8)      
    }
    else if(id==='resi'){
      owl.trigger('to.owl.carousel',9)
    } 
    if(id==='bnk'){
      owl.trigger('to.owl.carousel',10)      
    }
    else if(id==='nomi'){
      owl.trigger('to.owl.carousel',11)     
    }
    else if(id==='medi'){
      owl.trigger('to.owl.carousel',12) 
    }
    else if(id==='id'){
      owl.trigger('to.owl.carousel',13)      
    }
    else {
      owl.trigger('to.owl.carousel',0)   
    }         
    
    // $('.play').on('click',function(){
    //     owl.trigger('play.owl.autoplay',10)
    // })   
  })


}

function logoutdialog() {
  $(document).ready(function(){
    $('.mat-dialog-container').css("overflow-y","hidden")
    // $('.qrcode').css("height","80px")
  })

}


function resizeImageFn (settings) {
  var file = settings.file;
  var maxSize = settings.maxSize;
  var reader = new FileReader();
  var image = new Image();
  var canvas = document.createElement('canvas');
  var dataURItoBlob = function (dataURI) {
      var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
      var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var max = bytes.length;
      var ia = new Uint8Array(max);
      for (var i = 0; i < max; i++)
          ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
  };
  var resize = function () {
      var width = image.width;
      var height = image.height;
      if (width > height) {
          if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
          }
      } else {
          if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
          }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      var dataUrl = canvas.toDataURL('image/jpeg');
      dataUrl = dataUrl.replace(/^data:image\/[a-z]+;base64,/, "");
      return dataUrl;
      //return dataURItoBlob(dataUrl);
  };
  return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
      }
      reader.onload = function (readerEvent) {
          image.onload = function () { return ok(resize()); };
          image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
  });
};