$(function () {
  //Tabs click event

  $(document).on("click", ".nav-tabs li a", function (e) {
    e.preventDefault();
    $(this).closest(".nav-tabs").find("li").removeClass("active");
    $(this).parent().addClass("active");
    var tabPaneId = $(this).attr("href");
    $(this)
      .closest(".tab-panel")
      .find(">.tab-content>.tab-pane")
      .removeClass("active");
    $(tabPaneId).addClass("active");
  });

  $(document).on("click", ".id-page", function (e) {
    e.preventDefault();
    $(".id-page").removeClass("active");
    $(this).addClass("active");
    var coverId = $(this).attr("href");
    $(".id-cover").removeClass("active");
    $(coverId).addClass("active");
  });

  function scroll() {
    $(".scrollbar-outer").scrollbar();
  }
  $(".scrollbar-outer").scrollbar();

  $(document).on("click", ".upload-btn", function () {
    $(".message-modal").fadeIn();
  });

  $(document).on("click", ".message-modal .close", function () {
    $(".message-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".notification-modal .closeModal", function () {
    $(".notification-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });
  $(document).on("click", ".notification-modal .noti_body", function () {
    $(".notification-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".modal .close", function () {
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".collaboration-modal .close", function () {
    $(".collaboration-modal").fadeOut();
    $(".collaboration-modal").close();
  });

  $(document).on("click", ".request-modal .close", function () {
    $(".request-modal").fadeOut();
    $(".request-modal").close();
  });

  $(document).on("click", ".attendance-modal .close", function () {
    $(".attendance-modal").fadeOut();
    $(".attendance-modal").close();
  });

  $(document).on("click", ".notification-modal .close", function () {
    $(".notification-modal").fadeOut();
    $(".notification-modal").close();
  });

  $(document).on("click", ".profile-modal .close", function () {
    $(".profile-modal").fadeOut();
    $(".profile-modal").close();
  });

  $(document).on("click", ".option-modal .close", function () {
    $(".option-modal").fadeOut();
    $(".option-modal").close();
  });

  $(document).on("click", ".pulse-modal .close", function () {
    $(".pulse-modal").fadeOut();
    $(".pulse-modal").close();
  });

  $(document).on("click", ".inductionfeedback-modal .close", function () {
    $(".inductionfeedback-modal").fadeOut();
    $(".inductionfeedback-modal").close();
  });

  $(document).on("click", ".feedback-modal .close", function () {
    $(".feedback-modal").fadeOut();
    $(".feedback-modal").close();
  });

  $(document).on("click", ".onboarding-modal .close", function () {
    $(".onboarding-modal").fadeOut();
    $(".onboarding-modal").close();
  });

  $(document).on("click", ".modal .proceed", function () {
    $(".modal").fadeOut();
    $(".modal").close();
  });

  $("select").each(function () {
    var select_class = $(this).attr("class");
    $(this).selectmenu({
      classes: {
        "ui-selectmenu-button": select_class,
      },
    }); //.selectmenu('menuWidget').addClass('overflow');
  });

  $(document).on("click", ".message-modal .accept", function () {
    $(".message-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".modal .accept", function () {
    $(".headerInner_bg").fadeIn();
    $(".message-modal").fadeOut();
  });

  $(document).on("click", ".modal .decline", function () {
    $(".headerInner_bg").fadeIn();
    $(".message-modal").fadeOut();
  });

  $(document).on("click", ".message-modal .decline", function () {
    $(".message-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".message-modal .close1", function () {
    $(".message-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".primary-btn", function () {
    $(".message-modal").fadeOut();
  });

  $(document).on("click", ".btn-2", function () {
    $(".message-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(document).on("click", ".btn-21", function () {
    $(".message-modal").fadeOut();
    $(".headerInner_bg").fadeIn();
  });

  $(".material-input").each(function (index) {
    // in case the input is already filled..
    if ($.trim($(this).val()) !== "") {
      $(this).addClass("input_filled");
    }

    $(this).bind({
      focus: function () {
        $(this).addClass("input_filled");
      },
      blur: function () {
        if ($(this).val() === "") {
          $(this).removeClass("input_filled");
        }
      },
    });
  });

  $(".scrollContainer").scroll(function () {
    var selected = $("input[type='text']:focus");
    if (selected.length > 0) {
      $("#someButton").focus();
    }
  });

  $(document).on("click", ".otp-btn", function () {
    $(this).hide();
    // $('.otp-form').show();
    var parent1 = $(this).parent();
    parent1.first().next().show();
  });

  $(document).on("click", ".routing-info", function () {
    $(".headerInner_bg").fadeOut();
    $(".find-routing-modal").fadeIn();
  });

  $(document).on("click", ".view-profile", function () {
    $(".profile-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".view-request", function () {
    $(".request-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".view-collaboration", function () {
    $(".collaboration-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".open-profile", function () {
    $(".open-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".mailer-modal-link", function () {
    $(".mailer-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".option-modal-link", function () {
    $(".option-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".stay1-modal-link", function () {
    $(".stay1-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".stay2-modal-link", function () {
    $(".stay2-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".pulse-modal-link", function () {
    $(".pulse-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".inductionfeedback-modal-link", function () {
    $(".inductionfeedback-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".attendance-modal-link", function () {
    $(".attendance-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".notification-modal-link", function () {
    $(".notification-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".feedback-modal-link", function () {
    $(".feedback-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".onboarding-modal-link", function () {
    $(".onboarding-modal").fadeToggle();
    $(".modal-backdrop").fadeToggle("fade");
    $(".modal-backdrop").css("opacity", "0.5");
  });

  $(document).on("click", ".routing-info1", function () {
    $(".headerInner_bg").fadeOut();
    $(".find-routing-modal").fadeIn();
  });

  $(document).on("click", ".tnc-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".tnc-modal").fadeIn();
  });

  $(document).on("click", ".hr-modal-link", function () {
    $(".hr-modal").fadeIn();
  });

  $(document).on("click", ".hr2-modal-link", function () {
    $(".hr2-modal").fadeIn();
  });

  $(document).on("click", ".manager-modal-link", function () {
    $(".manager-modal").fadeIn();
  });

  $(document).on("click", ".call-modal-link", function () {
    $(".call-modal").fadeIn();
  });

  $(document).on("click", ".request-call-modal-link", function () {
    $(".request-call-modal").fadeIn();
  });

  $(document).on("click", ".request1-call-modal-link", function () {
    $(".request1-call-modal").fadeIn();
  });

  $(document).on("click", ".tnc3-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".tnc3-modal").fadeIn();
  });

  $(document).on("click", ".tnc2-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".tnc2-modal").fadeIn();
  });

  $(document).on("click", ".tnc1-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".tnc1-modal").fadeIn();
  });

  $(document).on("click", ".wage-modal-link", function () {
    $(".wage-modal").fadeIn();
  });

  $(document).on("click", ".accept-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".accept-modal").fadeIn();
  });

  $(document).on("click", ".ashi-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".ashi-modal").fadeIn();
  });

  $(document).on("click", ".privacyuk-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".privacyuk-modal").fadeIn();
  });

  $(document).on("click", ".conductuk-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".conductuk-modal").fadeIn();
  });

  $(document).on("click", ".whistlebloweruk-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".whistlebloweruk-modal").fadeIn();
  });

  $(document).on("click", ".personaldf-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".personaldf-modal").fadeIn();
  });

  $(document).on("click", ".privacydata-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".privacydata-modal").fadeIn();
  });

  $(document).on("click", ".administrative-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".administrative-modal").fadeIn();
  });

  $(document).on("click", ".confindisc-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".confindisc-modal").fadeIn();
  });

  $(document).on("click", ".empdeclarationuk-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".empdeclarationuk-modal").fadeIn();
  });

  $(document).on("click", ".antiuk-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".antiuk-modal").fadeIn();
  });

  $(document).on("click", ".visa-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".visa-modal").fadeIn();
  });

  $(document).on("click", ".conduct-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".conduct-modal").fadeIn();
  });

  $(document).on("click", ".joining-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".joining-modal").fadeIn();
  });

  $(document).on("click", ".relieved-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".relieved-modal").fadeIn();
  });

  $(document).on("click", ".edu-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".edu-modal").fadeIn();
  });

  $(document).on("click", ".edus-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".edus-modal").fadeIn();
  }); 

  $(document).on("click", ".IpDeclaration-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".IpDeclaration-modal").fadeIn();
  }); 

  $(document).on("click", ".joireport-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".joireport-modal").fadeIn();
  });

  $(document).on("click", ".confidential-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".confidential-modal").fadeIn();
  });

  $(document).on("click", ".whistleblower-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".whistleblower-modal").fadeIn();
  });

  $(document).on("click", ".bonusquater-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".bonusquater-modal").fadeIn();
  });

  $(document).on("click", ".bonusannual-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".bonusannual-modal").fadeIn();
  });

  $(document).on("click", ".ethics-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".ethics-modal").fadeIn();
  });

  $(document).on("click", ".work-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".work-modal").fadeIn();
  });

  $(document).on("click", ".fixedterm-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".fixedterm-modal").fadeIn();
  });
  
  $(document).on("click", ".privacy-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".privacy-modal").fadeIn();
  });

  $(document).on("click", ".mail-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".mail-modal").fadeIn();
  });

  $(document).on("click", ".tfn-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".tfn-modal").fadeIn();
  });

  $(document).on("click", ".atWill-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".atWill-modal").fadeIn();
  });

  $(document).on("click", ".confiInven-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".confiInven-modal").fadeIn();
  });

  $(document).on("click", ".mutualArbit-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".mutualArbit-modal").fadeIn();
  });

  $(document).on("click", ".competeSolicit-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".competeSolicit-modal").fadeIn();
  });

  $(document).on("click", ".benefitAnnex-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".benefitAnnex-modal").fadeIn();
  });

  $(document).on("click", ".antiharrassment-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".antiharrassment-modal").fadeIn();
  });

  $(document).on("click", ".disabilityaccomodation-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".disabilityaccomodation-modal").fadeIn();
  });

  $(document).on("click", ".drugfreework-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".drugfreework-modal").fadeIn();
  });

  $(document).on("click", ".employeehandbook-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".employeehandbook-modal").fadeIn();
  });

  $(document).on("click", ".unpaidleave-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".unpaidleave-modal").fadeIn();
  });

  $(document).on("click", ".equalemployment-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".equalemployment-modal").fadeIn();
  });

  $(document).on("click", ".whistleblowerus-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".whistleblowerus-modal").fadeIn();
  });

  $(document).on("click", ".CODAndEthics-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".CODAndEthics-modal").fadeIn();
  });

  $(document).on("click", ".Conflict-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".Conflict-modal").fadeIn();
  });

  $(document).on("click", ".EEOAAInfo-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".EEOAAInfo-modal").fadeIn();
  });

  $(document).on("click", ".unlawful-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".unlawful-modal").fadeIn();
  });

  $(document).on("click", ".whistleblower-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".whistleblower-modal").fadeIn();
  });

  $(document).on("click", ".privacyus-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".privacyus-modal").fadeIn();
  });
  $(document).on("click", ".privacyusca-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".privacyusca-modal").fadeIn();
  });
  $(document).on("click", ".conductus-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".conductus-modal").fadeIn();
  });
  $(document).on("click", ".conductusConfig-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".conductusConfig-modal").fadeIn();
  });
  $(document).on("click", ".passportIBPM-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".passportIBPM-modal").fadeIn();
  });
  $(document).on("click", ".mandatorydocsIBPM-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".mandatorydocsIBPM-modal").fadeIn();
  });
  $(document).on("click", ".JoiningReportIBPM-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".JoiningReportIBPM-modal").fadeIn();
  });
   // ev
   $(document).on("click", ".joiningReportEV-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".joiningReportEV-modal").fadeIn();
  });
  $(document).on("click", ".eduEV-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".eduEV-modal").fadeIn();
  });
  $(document).on("click", ".nsrEV-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".nsrEV-modal").fadeIn();
  });
  $(document).on("click", ".joiningChcklistEV-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".joiningChcklistEV-modal").fadeIn();
  });
  // ev ends
  $(document).on("click", ".workComp-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".workComp-modal").fadeIn();
  });
  $(document).on("click", ".privacyIN-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".privacyIN-modal").fadeIn();
  });
  $(document).on("click", ".ipIN-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".ipIN-modal").fadeIn();
  });
  $(document).on("click", ".eduIN-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".eduIN-modal").fadeIn();
  });
  $(document).on("click", ".ecIN-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".ecIN-modal").fadeIn();
  });
  $(document).on("click", ".laptopIN-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".laptopIN-modal").fadeIn();
  });
  $(document).on("click", ".semesterIN-modal-link", function () {
    $(".headerInner_bg").fadeOut();
    $(".semesterIN-modal").fadeIn();
  });

  //toggle the component with class accordion_body
  $(".accordion_head").click(function () {
    if ($(this).hasClass("active") == false) {
      $(".accordion_head,.accordion-item").removeClass("active");

      $(this).parent().addClass("active");
      $(this).addClass("active");
      if ($(".accordion_body").is(":visible")) {
        $(".accordion_body").slideUp(300);
      }
      if ($(this).next(".accordion_body").is(":visible")) {
        $(this).next(".accordion_body").slideUp(300);
      } else {
        $(this).next(".accordion_body").slideDown(300);
      }
    }
  });

  $(function () {
    $("#bt").on("click", function () {
      var name = $("#fileInput").val().split("\\").pop();
      name = name.split(".")[0];
      $("#fname").html(name);
    });
  });
});

